
export class User {
  uid: string;
  firstName = '';
  lastName = '';
  email: string;
  zipcode = '';
  interests: number[] = [];
  goals: number[] = [];
  profileImgUrl = '';
  country = '';
  age = '';
  warningAccepted;
  eventGroupList : any[] = [];
  sio : {} = {};
}

