import { Component, OnInit } from '@angular/core';
import { NgoService } from '../services/ngo.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../services/data.service';
import { NavController, ModalController, NavParams } from '@ionic/angular';
import { EventService } from '../shared/services/events/event.service';

@Component({
  selector: 'app-donate-amount',
  templateUrl: './donate-amount.page.html',
  styleUrls: ['./donate-amount.page.scss'],
})
export class DonateAmountPage implements OnInit {

  amount : number = 0;
  finalAmount : number =  0;
  ngoId : string = '';
  flow : string = '';
  ngoName : string = '';

  slideOpts = {
    slidesPerView: 3.2,
    freeMode: true,
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: false,
    },
    direction:'horizontal',
    spaceBetween:15,
    centeredSlides: false,
  }
  value: string;
  constructor(private ngoservice: NgoService,
    private route: ActivatedRoute, 
    private router: Router,
    private dataService: DataService,
    private navController: NavController,
    private eventService: EventService,
    public navCtrl: NavController,
    private modalController: ModalController,
    private navParams: NavParams,) { }

  ngOnInit() {
    this.ngoId = this.navParams.get('id');
    this.flow = this.navParams.get('flow');
    this.ngoName = this.navParams.get('ngoName');
    // this.route.queryParams.subscribe(params => {
    //   this.ngoId = params['id'];
    //   this.flow = params['flow'];
    //   this.ngoName = params['ngoName'];
      
    // })
  }

  amountSelected(num : number){
    if(num){
    this.finalAmount = num;
    this.amount = 0;
    }
  }

  
    numberOnlyValidation(event: any) {
       const pattern = /[0-9.,]/;
      let inputChar = String.fromCharCode(event.charCode);
      if (!pattern.test(inputChar)) {
        // invalid character, prevent input
        event.preventDefault();
      }
      
    }
  

  insertAmount(){
    this.finalAmount = this.amount;

  }

  // doPayment(){
  //   let navigationExtras = {
  //     queryParams: {
  //       flow: this.flow,
  //       id:this.ngoId,
  //       amount : this.finalAmount,
  //       ngoName : this.ngoName
  //     }
  //   };
  //   this.navController.navigateForward(['donate-stripe-payment'], navigationExtras);
  // }

  async doPayment(done: boolean) {
      let navigationExtras = {
          queryParams: {
            flow: this.flow,
            id:this.ngoId,
            amount : this.finalAmount,
            ngoName : this.ngoName
          }
        };
        
    if (done) {

      await this.modalController.dismiss(navigationExtras);
      //this.navController.navigateForward(['donate-stripe-payment'], navigationExtras);
      
    } else {
      await this.modalController.dismiss();
    }
  }

  back(){
    this.modalController.dismiss();
  }
 
  close(){
    // this.navController.back();
    this.modalController.dismiss();
  }
}
