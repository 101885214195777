import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { PetitionUser, PetitionUserMapping } from '../../models/petition_user_mapping.model';
import { Petition } from '../../models/petition.model';
import { Auth } from 'src/app/services/auth/auth';

@Injectable({
  providedIn: 'root'
})
export class PetitionService {
  petitionId: any
  selectedPetition: Petition;

  constructor(private db: AngularFirestore,
    private auth:Auth) { }

  addPetition(material): any {
    console.log('Petition Data', material);
    const param = JSON.parse(JSON.stringify(material));
    const self = this;
    return new Promise<firebase.firestore.DocumentReference>(function (resolve, reject) {
      self.db.collection('petition').add(param).then(doc => {
        resolve(doc);
      }, function (error) {
        reject(error);
      });
    });
  }

  fetchParticularPetiton(petitionID): Promise<any> {
    let data = [];
    const self = this;

    return self.db.collection('petition').doc(petitionID).get().toPromise();

  }

  fetchAllPetiton() {
    return this.db.collection("petition").get().toPromise()
  }


  fetchVolunteerCampaignPetition(petitionID): Promise<any> {
    console.log(petitionID)
    // let data  = [];
    // const self = this;
    // return new Promise<any>(function(resolve, reject) {
    //   self.db.collection('petition').ref.where('campaignId', '==', campaignID)
    //   .get().then(function (querySnapshot) {
    //     querySnapshot.forEach(function (doc) {
    //       let val = doc.data() as Petition;
    //       val['id'] = doc.id;
    //       data.push(val);

    //       });
    //       if(data.length > 0){
    //       resolve(data);
    //       }
    //       else{
    //         reject("No Pentition for particular campaign : " + campaignID);
    //       }
    //   }, function(error) {
    //     reject(error);
    //   });
    // });
    return this.db.collection('petition').doc(petitionID).get().toPromise();
  }

  fetchPetitionDetails(campaignID, petitionId) {
    let data = [];
    const self = this;
    return new Promise<any>(function (resolve, reject) {
      self.db.collection('petition-user-mapping').ref.where('campaignId', '==', campaignID).where('petitionId', '==', petitionId).orderBy('updated_on', 'desc')
        .get().then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            let val = doc.data() as PetitionUserMapping;
            val['id'] = doc.id;
            data.push(val);

          });
          if (data.length > 0) {
            resolve(data);
          }
          else {
            reject("No Pentition for particular petition : " + petitionId);
          }
        }, function (error) {
          reject(error);
        });
    });
  }

  fetchUserPetitionDetails(campaignID, petitionId, userId) {
    let data = [];
    const self = this;
    return new Promise<any>(function (resolve, reject) {
      self.db.collection('petition-user-mapping').ref
        .where('user.id', '==', userId)  
        .where('campaignId', '==', campaignID)
        .where('petitionId', '==', petitionId)
        .orderBy('updated_on', 'desc')
        .get().then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            let val = doc.data() as PetitionUserMapping;
            val['id'] = doc.id;
            data.push(val);

          });
          if (data.length > 0) {
            resolve(data[0]);
          }
          else {
            reject("No Pentition for particular petition : " + petitionId);
          }
        }, function (error) {
          reject(error);
        });
    });
  }

  addPetitionUserDetails(data: PetitionUserMapping): any {
    console.log('Pledge User Data', data);
    data.email = this.auth.user.email;
    const eventParam = Object.assign({}, data);
    let obj = new PetitionUser();
    obj['id'] = this.auth.user.uid;
    obj['firstName'] = this.auth.user.firstName;
    obj['lastName'] = this.auth.user.lastName;
    eventParam['user'] = Object.assign({}, obj);
    eventParam['updated_on'] = new Date();
    const self = this;
    return new Promise<firebase.firestore.DocumentReference>(function (resolve, reject) {
      self.db.collection('petition-user-mapping').add(eventParam).then(doc => {
        resolve(doc);
      }, function (error) {
        reject(error);
      });
    });
  }

  updatePetitionUserDetails(data: PetitionUserMapping): any {
    console.log('Pledge User Data', data);
    const eventParam = Object.assign({}, data);
    eventParam['updated_on'] = new Date();
    const self = this;
    return new Promise<void>(function (resolve, reject) {
      self.db.collection('petition-user-mapping').doc(data.id).update(eventParam).then(doc => {
        resolve();
      }, function (error) {
        reject(error);
      });
    });
  }


}
