
export class PetitionUserMapping {
  id: string = '';
  campaignId: string = '';
  user: PetitionUser = new PetitionUser();
  petitionId : string = '';
  status: number = 0;
  updated_on : Date;
  deadline : Date;
  signature_goal: number = 0;
  email: string = '';
  zipCode: string = '';
}
export class PetitionUser {
  id: string = '';
  firstName: string = '';
  lastName: string = '';
}

