import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { AlertType, IAlertButton } from '../shared/services/alert/alert.service';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-prompt-modal',
  templateUrl: './prompt-modal.page.html',
  styleUrls: ['./prompt-modal.page.scss'],
})
export class PromptModalPage implements OnInit {
  heading: any;
  mode: any;

  alertType: AlertType
  headerTitle: string = '';
  messageText: string = '';
  buttonList: IAlertButton[];
  partnerName : string = '';


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalController: ModalController,  navParams: NavParams) {
    
    this.heading =  navParams.get('message');
    this.mode = navParams.get('mode')
    this.partnerName = navParams.get('partnerName');
    console.log( "heading modal =>",this.heading)
    console.log( "mode modal =>",this.mode)
   }

  ngOnInit() {
  }

  // async closeModal() {
  //   let onClosedData = '';
  //   if (done) {
  //     await this.modalController.dismiss(onClosedData);
  //   } else {
  //     await this.modalController.dismiss();
  //   }
  // }
 routeTo(onDismiss) {
    this.closeModal(onDismiss);
  }

  async closeModal(onDismiss) {
  
    if (onDismiss) {
      await this.modalController.dismiss(onDismiss);
    } else {
      await this.modalController.dismiss();
    }
  }

  // goBack(){
  //   this.modalController.dismiss();
  // }

  // async closeModal() {
  //   this.modalController.dismiss();
  // }
  signOutYes(getData) {
    this.modalController.dismiss({
      'signout':getData
  })
  }

  async acceptPacky(getDataPacky) {
    this.modalController.dismiss({
      'getDataPacky':getDataPacky
  })
  }
  gotoBackground() {
    this.router.navigateByUrl('background');
  }

  dismiss(type) {
    this.modalController.dismiss({
      'getDetaskillBase':type
  })
  }

  cancelVol(getData){
    this.modalController.dismiss({
      'cancelVol':getData
  })
  }
}
