import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { Device } from '@ionic-native/device/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { HttpClientModule } from '@angular/common/http';
import {DatePipe} from '@angular/common';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { Facebook } from '@ionic-native/facebook/ngx';
import { SignInWithApple} from '@ionic-native/sign-in-with-apple/ngx';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { DatePicker } from '@ionic-native/date-picker/ngx';
import { IonicImageLoader } from 'ionic-image-loader';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { FCM } from '@ionic-native/fcm/ngx';

import { firebaseConfig } from './firebase.variables';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService, DEBUG_MODE } from '@angular/fire/analytics';
import { AngularFirePerformanceModule } from '@angular/fire/performance';
import { MomentModule } from 'ngx-moment';
import { CausesPageModule } from './shared/components/causes/causes.module';
import { CausesPage } from './shared/components/causes/causes.page';
import { DevelopmentGoalsPage } from './shared/components/development-goals/development-goals.page';
import { DevelopmentGoalsPageModule } from './shared/components/development-goals/development-goals.module';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { ImagePicker } from '@ionic-native/image-picker/ngx';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { MediaCapture } from '@ionic-native/media-capture/ngx';
import { Contacts } from '@ionic-native/contacts/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { File } from '@ionic-native/file/ngx';
import { Chooser } from '@ionic-native/chooser/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import {AngularFireStorageModule} from '@angular/fire/storage';
import '@stripe/stripe-js';
import { SocialLoginModule, AuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider } from "angular-6-social-login";
import { AuthService } from "angular-6-social-login";
import { JwSocialButtonsModule } from 'jw-angular-social-buttons';
import { googleAuthConfig, faceBookAuthConfig, environment } from '../environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { PartialPageModule } from './shared/components/partial/partial.module';
import { PartialPage } from './shared/components/partial/partial.page';
import{CalendarModule} from 'ion2-calendar';
import { DonateAmountPage } from './donate-amount/donate-amount.page';
import { DonateAmountPageModule } from './donate-amount/donate-amount.module';
import { LoginModalPageModule } from './login-modal/login-modal.module';
import { LoginModalPage } from './login-modal/login-modal.page';
import { PromptModalPage } from './prompt-modal/prompt-modal.page';
import { PromptModalPageModule } from './prompt-modal/prompt-modal.module';
import { FullCalendarModule } from '@fullcalendar/angular'; // the main connector. must go first
import rrulePlugin from '@fullcalendar/rrule'
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import { QrcodePageModule } from './qrcode/qrcode.module';
import { QRScanner } from '@ionic-native/qr-scanner/ngx';
import { QrcodePage } from './qrcode/qrcode.page';




export function getAuthServiceConfigs() {
  let config = new AuthServiceConfig(
      [
        {
          id: FacebookLoginProvider.PROVIDER_ID,
          provider: new FacebookLoginProvider(faceBookAuthConfig.appId)
        },
        {
          id: GoogleLoginProvider.PROVIDER_ID,
          provider: new GoogleLoginProvider(googleAuthConfig.clientId)
        }
      ]
  );
  return config;
}
FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  rrulePlugin,
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin
]);

@NgModule({
  declarations: [AppComponent],
  entryComponents: [CausesPage, PartialPage,DonateAmountPage
    ,LoginModalPage,PromptModalPage,DevelopmentGoalsPage,QrcodePage],
  imports: [
     BrowserModule,
     HttpClientModule,
     IonicModule.forRoot(),
     AppRoutingModule,
     AngularFireModule.initializeApp(firebaseConfig),
     AngularFireAuthModule,
     AngularFirestoreModule,
     AngularFireStorageModule,
    AngularFireDatabaseModule,
    AngularFireFunctionsModule,
    AngularFirePerformanceModule,
    AngularFireAnalyticsModule,
    MomentModule,
    CausesPageModule,
    QrcodePageModule,
    DevelopmentGoalsPageModule,
    HttpClientModule,
    CalendarModule,
    FullCalendarModule,
    DonateAmountPageModule,
    LoginModalPageModule,
    JwSocialButtonsModule,
    PromptModalPageModule, 
    IonicStorageModule.forRoot({
      name: '__mydb',
      driverOrder: ['indexeddb', 'sqlite', 'websql']
    }),
    IonicImageLoader.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    PartialPageModule
    ],
  providers: [Device,
    StatusBar,
    SplashScreen,
    GooglePlus,
    NativeStorage,
    Facebook,
    SignInWithApple,
    Geolocation,
    AndroidPermissions,
    LocationAccuracy,
    SocialSharing,
    AppVersion,
    InAppBrowser,
    DatePicker,
    WebView,
    File,
    Chooser,
    FilePath,
    Camera,
    ImagePicker,
    AuthService,
    ScreenTrackingService,
    UserTrackingService,
    { provide: DEBUG_MODE, useValue: false },
    DatePipe,
    Keyboard,
    FirebaseX,
    Contacts,
    MediaCapture,
    QRScanner,
    {
      provide: AuthServiceConfig,
      useFactory: getAuthServiceConfigs
    },
    FCM,{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
