import { Component, OnInit, Input, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { NavController, ModalController,Platform } from '@ionic/angular';
import { Auth } from 'src/app/services/auth/auth';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import * as moment from 'moment';
import { DataService } from 'src/app/services/data.service';
import { Event } from 'src/app/shared/models/event.model';

@Component({
  selector: 'app-partial',
  templateUrl: './partial.page.html',
  styleUrls: ['./partial.page.scss'],
})
export class PartialPage implements OnInit {

  @Input() text = 'I just donated through BackPac to help fight Covid-19. Come join me and spread the word. #spreadthemovement #backpac #Covid-19 #donate';
  @ViewChild('element') element: ElementRef;
  @Input() urlShown = 'http://backpac.co';
  @Input() media = 'assets/backpac-logo.svg';
  isWebPlatform = false;
  showWebSocialIcons = false;
  showIcons : boolean = false;
  event : Event = new Event();
  

  constructor(
    private navCtrl: NavController,
    private modalController: ModalController,
    private auth: Auth,
    private socialSharing: SocialSharing,
    private pltform: Platform,
    private dataService : DataService,
  ) {
    this.event = this.dataService.event;
    if (!this.pltform.is('mobileweb') && this.pltform.is('desktop')) {
      this.isWebPlatform = true;
      // load facebook sdk if required
      const facebookUrl = 'https://connect.facebook.net/en_US/sdk.js';
      if (!document.querySelector(`script[src='${facebookUrl}']`)) {
        let script = document.createElement('script');
        script.src = facebookUrl;
        document.body.appendChild(script);
      }

      // load twitter sdk if required
      const twitterUrl = 'https://platform.twitter.com/widgets.js';
      if (!document.querySelector(`script[src='${twitterUrl}']`)) {
        let script = document.createElement('script');
        script.src = twitterUrl;
        document.body.appendChild(script);
      }

      // load twitter sdk if required
      const linkdinUrl = 'https://platform.linkedin.com/in.js';
      if (!document.querySelector(`script[src='${linkdinUrl}']`)) {
        let script = document.createElement('script');
        script.src = linkdinUrl;
        script.innerHTML = ' lang: en_US';
        document.body.appendChild(script);
      }

      // load pinterest sdk if required
      const pinurl = 'https://assets.pinterest.com/js/pinit.js';
      if (!document.querySelector(`script[src='${pinurl}']`)) {
        let script = document.createElement('script');
        script.src = pinurl;
        script['data-pin-build'] = 'parsePins';
        document.body.appendChild(script);
      }
    }
    else {
      this.isWebPlatform = false;
    }
  }

  ngOnInit() {
    if (!this.pltform.is('mobileweb') && this.pltform.is('desktop')) {
      this.isWebPlatform = true;
    }
    else {
      this.isWebPlatform = false;
    }
    this.text =  'I am volunteering with ' + this.event.ngo['name'] +' on ' + moment(this.event.startTime).format('MM/DD/YYYY') +
    '. Join me to create impact in our city. http://backpac.co';
  }

  ngAfterViewInit(): void {
    if(this.isWebPlatform){
    window['FB'] && window['FB'].XFBML.parse();
    window['twttr'] && window['twttr'].widgets.load();
    // this.element.nativeElement.innerHTML = `<script type="IN/Share" data-url="${this.urlShown}" data-text="${this.text}"></script>`;
    //document.getElementById("element").innerHTML = `<script type="IN/Share" data-url="${this.urlShown}" data-text="${this.text}"></script>`;
    window['IN'] && window['IN'].parse();
    window['parsePins'] && window['parsePins']();
    }
  }

  back() {
    this.modalController.dismiss();
  }

  showSocialIcons(){
    //this.showIcons = true;
    if(!this.isWebPlatform){
      const options = {
        message: this.text, 
       };
      console.log('options to share', options);
      // Share via email
      this.socialSharing.shareWithOptions(options).then(() => {
        console.log('Sharing successful');
      }).catch(() => {
        console.log('Sharing failed!');
      });
    }
    else{
      this.showIcons = true;
    }
  }

  closeModal(){
    this.modalController.dismiss();
  }
}
