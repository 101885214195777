// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

export const firebaseServerConfig = {
  apiKey: 'AIzaSyBPiGsv4wxfakt7HbTUh2g7mZMcpv4A2ho',
  authDomain: 'backpactest.firebaseapp.com',
  databaseURL: 'https://backpactest.firebaseio.com',
  projectId: 'backpactest',
  storageBucket: 'backpactest.appspot.com',
  messagingSenderId: '228091188067',
  appId: '1:228091188067:web:7441249fdf685bb0',
  measurementId: "G-SPKGB5F5TG"
  
};

export const stripeServerConfig = {
  apiKey: 'pk_test_AnXJwAnRcKFIvkOI6o9h1BET00rZUO6CWy'
};

export const googleAuthConfig = {
  clientId: '228091188067-ek1eu7tbg5jsc0pencap0qh2jgm62qs8.apps.googleusercontent.com'
};

export const faceBookAuthConfig = {
  appId: '273124993810975'
};

export const googleMapBrowserKey = {
  key: 'AIzaSyBPiGsv4wxfakt7HbTUh2g7mZMcpv4A2ho'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
