import { AlertService } from './../../services/alert/alert.service';
import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, AlertController, NavController } from '@ionic/angular';
import { Utils } from '../../utils/utils';
import * as _ from 'underscore';

@Component({
  selector: 'app-causes',
  templateUrl: './causes.page.html',
  styleUrls: ['./causes.page.scss'],
})


export class CausesPage implements OnInit {
  causeObjArr = _.clone(Utils.causeObjArr);

  noOfRows = 0;
  rowCountArray = [0, 2, 4, 6, 8, 10];
  selelectCause1 = [];
  selectedCause = [];
  signUpButtonCause: string = ' ';
  profileCauseSaveButton: string = ' ';

  constructor(private modalController: ModalController,
    private navParams: NavParams,
    public alertController: AlertController,
    public navCtrl: NavController,
    private alertSrvc: AlertService) {

    const indexOfLGBT = this.causeObjArr.map(function (e) { return e.label; }).indexOf('LGBTQ+');
    this.causeObjArr.splice(indexOfLGBT, 1);
  }

  ngOnInit() {
    this.noOfRows = Math.floor(this.causeObjArr.length / 2);
    this.selelectCause1 = this.navParams.get('selectedCauses');
    this.selectedCause = Object.assign([], this.selelectCause1);;
    if (this.navParams.get('screenBackButton')) {
      this.signUpButtonCause = this.navParams.get('screenBackButton');
    }
    if (this.navParams.get('saveBooleanButton')) {
      this.profileCauseSaveButton = this.navParams.get('saveBooleanButton');
    }
    console.log(this.selectedCause);
    // this.modelId = this.navParams.data.paramID;
    // this.modalTitle = this.navParams.data.paramTitle;
  }

  isSelectedIcon(index) {
    return this.selectedCause.indexOf(index) !== -1;
  }

  getSelectionIconName(index) {
    return this.isSelectedIcon(index) ? "checkmark-circle" : "radio-button-off";
  }

  getSelectionStyleClass(index) {
    return this.isSelectedIcon(index) ? "selected hydrated" : "unselected hydrated";
  }

  async closeModal(done: boolean) {
    const onClosedData = this.selectedCause;
    if (done) {
      if (onClosedData.length > 0) {
        await this.modalController.dismiss(onClosedData);
      }
      else {
        // this.presentAlert("Oh, no! You forgot to add something to your shopping bag")
        this.alertSrvc.showWarning("Oh, no!", ' You forgot to add your cause', "Ok", null)

      }
    } else {
      await this.modalController.dismiss();
    }
  }

  toggleSelection(data) {
    console.log(data);
    const index = this.selectedCause.indexOf(data);
    if (index === -1) {
      this.selectedCause.push(data);
    } else {
      this.selectedCause.splice(index, 1);
    }

    console.log(this.selectedCause);
  }


  // async presentAlert(msg: string) {
  //   var self = this;
  //   var head;
  //     head = 'Alert';
  //   const alert = await this.alertController.create({
  //     header: head,
  //     message: msg,
  //     buttons: [
  //       {
  //         text: 'Ok',
  //         handler: () => {
  //           console.log('Confirm Ok');

  //         }
  //       }
  //     ]
  //   });

  //   await alert.present();
  // }


  back() {
    this.modalController.dismiss(this.selelectCause1);
  }

  async closeCauseModal(done: boolean) {
    const objParams = this.selectedCause;
    if (done) {
      if (objParams.length > 0) {
        await this.modalController.dismiss(objParams);
      }
      else {
        this.alertSrvc.showWarning("Oh, no!", ' You forgot to add your cause', "Ok", null)
      }
    } else {
      await this.modalController.dismiss();
    }
  }
}
