import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { Event } from '../shared/models/event.model';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Utils } from '../shared/utils/utils';
import { Constants } from '../shared/utils/constants';
import { Auth } from './auth/auth';
import { User } from '../model/User';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { Platform } from '@ionic/angular';
import { DCartItem } from '../model/DCartItem';
import { Transaction } from '../model/Transaction';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { RRule, RRuleSet, rrulestr } from 'rrule'
import { EventVolunteerRecord } from '../shared/models/event_volunteer_record.model';
import { Storage } from '@ionic/storage';
import { EventService } from '../shared/services/events/event.service';


@Injectable({
  providedIn: 'root'
})

export class DataService {
  recurringEvents: Event[] = [];
  events: Event[] = [];
  event: Event;
  currentLat: Number;
  currentLng: Number;
  user_entered_zip_code = '';
  categoryChoose: number;
  isLoading = false;
  totalDonateAmount: number = 0.00;
  totalDonateImpact: number = 0;
  signupUID: string = '';
  signupEmail: string = '';
  categoryName: string = '';
  cart: DCartItem[];
  latitude: any = null;
  longitude: any = null;
  volunteerScheduleEvents: string;
  partnerEvents: Event[] = [];
  pageName: string;
  partnerSelectedForDonation  = {};
  needToRefreshHomePage : boolean = false;
  paymentIDOfDonation : string = ''
  nextPageFromMyOpportunity : string = '';
  idOfParticularSIO : string = '';
  nameOfParticularSIO : string = '';
  subDomainOfParticularSIO : string = '';
  isSubDomainPresent : boolean = false;
  qrCodeScannedResult : string = '';
  isCheckIn : boolean = false;
  isCheckOut : boolean = false;
  ignoreFlag : boolean = false;
  ignoreSpreadMovementFlag : boolean = false;
  ngoChatId : string = ''


  private _currentUser: User;

  constructor(private firestore: AngularFirestore,
    private geolocation: Geolocation,
    private androidPermissions: AndroidPermissions,
    private locationAccuracy: LocationAccuracy,
    private auth: Auth,
    private pltform: Platform,
    private httpClient: HttpClient,
    private storage: Storage,) {

    
  }

  processResolveLatLng(){
    this._currentUser = this.auth.user;
    if(this._currentUser){
      this.storage.get('reverse_zipcode').then((val)=>{
        if(val && val === 'done'){
          
        }
        else{
          if(this._currentUser.zipcode){
          this.fetchLatLongFromZipcode(this._currentUser.zipcode);
          }
          else{
            this.currentLat = 42.2808;
            this.currentLng = -83.7430;
          }
        }
      }).catch (error => {
        console.log("Error while fetching zipcode reversal chack from local storage");
      })
    
    }
    console.log('----- ---- Registering for Geo Location:', this.geolocation);
    if (!(this.pltform.is('mobileweb') || this.pltform.is('desktop') || this.pltform.is('ios') || this.pltform.is('android'))) {
      this.checkGPSPermission();
    }
    else {
      this.getLocationCoordinates();
    }
    console.log('----- ---- Registered for Geo Location:');
  }

  // Methos to get device accurate coordinates using device GPS
  getLocationCoordinates() {
    this.geolocation.getCurrentPosition().then((resp) => {
      console.log('----- ---- Current Geo Location:', resp.coords);
      this.currentLat = resp.coords.latitude;
      this.currentLng = resp.coords.longitude;
      // this.evtSer.currentLat = resp.coords.latitude;
      // this.evtSer.currentLng = resp.coords.longitude;
    }).catch((error) => {
      console.log('Error getting location', error);
    });


    const watch = this.geolocation.watchPosition();
    watch.subscribe((data) => {
      // data can be a set of coordinates, or an error (if an error occurred).
      console.log('----- ---- Watching Geo Location:', data);
      if (data.coords) {
        console.log('----- ---- watching Geo Location:', data.coords);
        this.currentLat = data.coords.latitude;
        this.currentLng = data.coords.longitude;
      }
    });
  }

  checkGPSPermission() {
    this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.ACCESS_COARSE_LOCATION).then(
      result => {
        if (result.hasPermission) {

          //If having permission show 'Turn On GPS' dialogue
          this.askToTurnOnGPS();
        } else {

          //If not having permission ask for permission
          this.requestGPSPermission();
        }
      },
      err => {
        alert(err);
      }
    );
  }

  requestGPSPermission() {
    this.locationAccuracy.canRequest().then((canRequest: boolean) => {
      if (canRequest) {
        console.log("4");
      } else {
        //Show 'GPS Permission Request' dialogue
        this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.ACCESS_COARSE_LOCATION)
          .then(
            () => {
              // call method to turn on GPS
              this.askToTurnOnGPS();
            },
            error => {
              //Show alert if user click on 'No Thanks'
              console.log('requestGPSPermission Error requesting location permissions ', error);
              // alert('Oops, An error occurred while requesting for location permissions.')
            }
          );
      }
    });
  }

  askToTurnOnGPS() {
    // this.locationAccuracy.request(this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY).then(
    //   () => {
    //     // When GPS Turned ON call method to get Accurate location coordinates
    //     this.getLocationCoordinates();
    //   },
    //   error => alert('Error requesting location permissions ' + JSON.stringify(error))
    // );

    this.locationAccuracy.canRequest().then((canRequest: boolean) => {

      if(canRequest) {
        // the accuracy option will be ignored by iOS
        this.locationAccuracy.request(this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY).then(
          () => {
            console.log('Request successful');
            this.getLocationCoordinates();
          },
          error => console.log('Error requesting location permissions', error)
        );
      }
    
    }).catch(error => {
      console.log('Error requesting location permissions', error)
    })
      
  }


  // read_my_opportunity(upcoming, callBackOnEventChange) {
  //   const self = this;
  //   // TODO: Need to plan for exceptions like No event on 4th of July
  //   const oneTimeEvents = this.firestore.collection(Constants.Collection_Volunteer_User_Event, ref => {
  //     let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
  //     const email = this.auth.getCurrentUser();
  //     if (upcoming) {
  //       query = query.where('email', '==', email)
  //         .where('cancelledByUser', '==', false)
  //         .where('date', '>=', new Date())
  //         .orderBy('date');
  //     } else {
  //       query = query.where('email', '==', email)
  //         .where('cancelledByUser', '==', false)
  //         .where('date', '<', new Date())
  //         .orderBy('date', 'desc');
  //     }

  //     return query;
  //   }).snapshotChanges();

  //   let eventData = [];
  //   oneTimeEvents.subscribe(data => {
  //     eventData = data.map(e => {
  //       const data = e.payload.doc.data();
  //       const lat = data['latlong'] ? data['latlong']._lat : this.latitude;
  //       const lng = data['latlong'] ? data['latlong']._long : this.longitude;
  //       console.log("Final " + "lat :" + lat + " " + "  lng : " + lng);
  //       const tempEvent = new Event().deserialize(data);
  //       tempEvent.distance = tempEvent.lat !== 0 && tempEvent.lng !== 0 ?
  //         Utils.distance(self.currentLat, self.currentLng, tempEvent.lat, tempEvent.lng, 'M') : -1;
  //       tempEvent.setEventId(e.payload.doc.id);

  //       return tempEvent;
  //     });
  //     self.events = eventData as Event[];
  //     callBackOnEventChange(self.events);
  //   });
  //   return self.events;
  // }

  // read_my_opportunity(type : boolean) {
  //   const self = this;
  //   const email = self.auth.getCurrentUser();
  //   if (type) {
  //     return new Promise<any>(function (resolve, reject) {
  //       self.firestore.collection(Constants.Collection_Volunteer_User_Event).ref.where('email', '==', email)
  //       .where('cancelledByUser', '==', false)
  //       .where('date', '>=', new Date()).where('isDeleted', '==', false)
  //       .orderBy('date').get().then(function (querySnapshot) {
  //         let ev = [];
  //         querySnapshot.forEach(function (doc) {
  //           let obj = doc.data();
  //           obj['id'] = doc.id;
  //           ev.push(obj);
  //         });
  //         resolve(ev);
  //       }, function (error) {
  //         reject(error);
  //       });
  //     });
  //   }
  //   else {
  //     return new Promise<any>(function (resolve, reject) {
  //       self.firestore.collection(Constants.Collection_Volunteer_User_Event).ref.where('email', '==', email)
  //       .where('cancelledByUser', '==', false)
  //       .where('date', '<', new Date()).where('isDeleted', '==', false)
  //       .orderBy('date', 'desc').get().then(function (querySnapshot) {
  //         let ev = [];
  //         querySnapshot.forEach(function (doc) {
  //           let obj = doc.data();
  //           obj['id'] = doc.id;
  //           ev.push(obj);
  //         });
  //         resolve(ev);
  //       }, function (error) {
  //         reject(error);
  //       });
  //     });
  //   }

      
  // }

  read_my_opportunity(type : boolean) {
    const self = this;
    const email = self.auth.getCurrentUser();
    if (type) {
      return new Promise<any>(function (resolve, reject) {
        self.getEventsQuery(type,email).then(function (querySnapshot) {
          let ev = [];
          querySnapshot.forEach(function (doc) {
            let obj = doc.data();
            obj['id'] = doc.id;
            ev.push(obj);
          });
          resolve(ev);
        }, function (error) {
          reject(error);
        });
      });
    }
    else {
      return new Promise<any>(function (resolve, reject) {
        self.getEventsQuery(type,email).then(function (querySnapshot) {
          let ev = [];
          querySnapshot.forEach(function (doc) {
            let obj = doc.data();
            obj['id'] = doc.id;
            ev.push(obj);
          });
          resolve(ev);
        }, function (error) {
          reject(error);
        });
      });
    }

      
  }

  getEventsQuery(type : boolean,email : string) {
    const self = this;
    
    return this.firestore.collection(Constants.Collection_Volunteer_User_Event, ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      if (type) {
        
        query = query.where('email', '==', email)
          .where('cancelledByUser', '==', false)
          .where('startTimestamp', '>=', new Date()).where('isDeleted', '==', false)
          .orderBy('startTimestamp', 'desc')
      } else {
       
        query = query.where('email', '==', email)
        .where('cancelledByUser', '==', false)
        .where('startTimestamp', '<', new Date()).where('isDeleted', '==', false)
        .orderBy('startTimestamp', 'desc')
      }

      //Changes starts for Sio Event functionality
      if(self.idOfParticularSIO && self.idOfParticularSIO != ''){
        query = query.where('ngo.id', '==', self.idOfParticularSIO )
      }
      //Changes ends for Sio Event functionality
      return query;
    }).get().toPromise();
  }
  
  selectedEvent(event: Event) {
    this.event = event;
  }

  formatDate(date: Date): string {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return day + '/' + month + '/' + year;

  }

  submitDetailsForLocationNotification(name, email, zipCode) {
    const params = {
      'name': name,
      'email': email,
      'zipCode': zipCode
    };
    this.firestore.collection(Constants.Collection_Volunteer_User_Notify).add(params);
  }

  signup_opportuinity(data: Event, name: string, email: string, slotSelected: number, slotStartTime: Date, slotEndTime: Date) {

    if (!data.lat) {
      data.lat = 0;
    }
    if (!data.lng) {
      data.lng = 0;
    }
    const paramEvent = Object.assign({}, data);
    if (data.parent) {
      paramEvent.parent = Object.assign({}, data.parent);
    }
    // if(data && data.slotArr && data.slotArr[0] === 'self-paced'){
    paramEvent['email'] = email;
    paramEvent['full_name'] = name;
    paramEvent['slotSelected'] = slotSelected;
    paramEvent['slotStartTime'] = slotStartTime;
    paramEvent['slotEndTime'] = slotEndTime;
    //paramEvent['isDeleted'] = false;
    // }
    // else{
    //   paramEvent['email'] = email;
    //   paramEvent['full_name'] = name;
    //   paramEvent['slotSelected'] = slotSelected;
    //   paramEvent['slotStartTime'] = slotStartTime;
    //   paramEvent['slotEndTime'] = slotEndTime;
    //   paramEvent['slotArr'] = slotArr;
    // }


    return this.firestore.collection(Constants.Collection_Volunteer_User_Event).add(paramEvent);
  }

  cancelUserSubscriptionForEvent(id: string) {
    return this.firestore.collection(Constants.Collection_Volunteer_User_Event).doc(id).update({
      // Let's set this time on the server.
      // userCancellationTime: new Date(),
      cancelledByUser: true
    });
  }

  get_user_hours(email: string, callBack: any) {
    const self = this;

    // TODO: Need to plan for exceptions like No event on 4th of July

    const oneTimeEvents = this.firestore.collection(Constants.Collection_Volunteer_User_Event, ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;

      query = query
        .where('email', '==', email)
        .where('cancelledByUser', '==', false)
        .where('date', '<', new Date()).where('isDeleted', '==', false)
        .orderBy('date', 'desc');


      //Changes starts for Sio Event functionality
      if(self.idOfParticularSIO && self.idOfParticularSIO != ''){
        query = query.where('ngo.id', '==', self.idOfParticularSIO )
      }
      //Changes ends for Sio Event functionality

      // if (this.searchContext["interests"]) {
      //   query = query.where('interest', 'array-contains', this.searchContext["interests"])
      // };
      return query;
    }).snapshotChanges();

    const communityPartnerList = new Map();
    const resp = [0, 0, 0];
    oneTimeEvents.subscribe(data => {
      data.forEach(function (e) {
        // Check Checkin Time
        const checkInTimestamp = (e.payload.doc.data()['checkInTime']);
        let checkOutTime = null;
        if (e.payload.doc.data()['checkOutTime']) {
          checkOutTime = (e.payload.doc.data()['checkOutTime']).toDate()
        }
        let eventHour = 0;
        // if (checkInTimestamp && checkOutTime) {
        //   const checkInTime = checkInTimestamp.toDate();

        //   if (checkOutTime != null && checkOutTime !== undefined) {
        //     eventHour = (checkOutTime.getTime() -
        //       checkInTime.getTime()) / (3600 * 1000);
        //   } else {
        //     const minimumSlotDuration = e.payload.doc.data()['minimumSlotDuration'];
        //   }

        // Temp below code : Client do not want to use checkout time logic, wants to go with only check in time
        if (checkInTimestamp) {
          if (e.payload.doc.data()['minimumSlotDuration']) {
            eventHour = e.payload.doc.data()['minimumSlotDuration'];
          } else {
            if (e.payload.doc.data()['repeat'] && e.payload.doc.data()['repeat'][0] === 'Once') {
              eventHour = self.calculateHours(e.payload.doc.data());
            }
            else if (e.payload.doc.data()['repeat'] && e.payload.doc.data()['repeat'][0] === 'self-paced') {
              eventHour = e.payload.doc.data()['duration'];
            }
            else if (e.payload.doc.data()['repeat'] && e.payload.doc.data()['repeat'][0] != 'self-paced' && e.payload.doc.data()['repeat'][0] != 'Once') {
              eventHour = self.calculateHours(e.payload.doc.data());
            }
          }



          //Code block end

          resp[0] = resp[0] + eventHour;
          resp[1] = resp[1] + 1;
          const communityPartner = e.payload.doc.data()['ngo'];

          let hours = communityPartnerList.get(communityPartner.name) ? communityPartnerList.get(communityPartner.name).hours : 0;

          hours = hours + eventHour;

          communityPartnerList.set(communityPartner.name, {
            hours: hours,
            causes: communityPartner.causes ? communityPartner.causes : ''
          });
        } else {
          resp[2] = resp[2] + 1;
          const communityPartner = e.payload.doc.data()['ngo'];
          const hours = communityPartnerList.get(communityPartner.id) ? communityPartnerList.get(communityPartner.id).hours : 0;
          communityPartnerList.set(communityPartner.name, {
            hours: hours,
            causes: communityPartner.causes ? communityPartner.causes : ''
          });
        }
      });
      callBack(communityPartnerList, resp);
    });
  }

  calculateHours(day) {
    let hours = 0;
    if (day['slotSelected']) {
      let slotSel = parseInt(day['slotSelected']);

      let slotFound = day['slotArr'][slotSel];
      var duration = moment.duration(moment(slotFound.endTime.toDate()).diff(moment(slotFound.startTime.toDate())));
      hours = parseFloat(duration['_data'].hours + '.' + duration['_data'].minutes);


    }
    return hours;
  }

  updateUserData(updatedUser: User, userid: string): Promise<void> {
    console.log('User:', updatedUser);
    const param = JSON.parse(JSON.stringify(updatedUser));
    const self = this;
    return new Promise(function (resolve, reject) {
      self.firestore.collection(Constants.Collection_Volunteer_Profile).doc(userid).update(param).then(function () {
        self._currentUser = updatedUser;
        resolve();
      }, function (err) {
        reject(err);
      });
    });
  }

  updateUserInterests(interests: number[]): Promise<void> {
    // console.log('User:', updatedUser);
    const param = { 'interests': interests };
    const self = this;
    return new Promise(function (resolve, reject) {
      self.firestore.collection(Constants.Collection_Volunteer_Profile).doc(self.auth.currentUserId).update(param).then(function () {
        self.auth.user.interests = interests;
        self._currentUser = self.auth.user;
        resolve();
      }, function (err) {
        reject(err);
      });
    });
  }

  updateUserInterestsForAnonymous(interests: number[],updatedUser: User): Promise<void> {
    // console.log('User:', updatedUser);
    const param = { 'interests': interests };
    const self = this;
    return new Promise(function (resolve, reject) {
      self.firestore.collection(Constants.Collection_Volunteer_Profile).doc(self.auth.currentUserId).update(param).then(function () {
        //self.auth.user.interests = interests;
        self._currentUser = updatedUser;
        resolve();
      }, function (err) {
        reject(err);
      });
    });
  }


  updateUserInterestsByProfile(interests: number[], userId: string): Promise<void> {
    // console.log('User:', updatedUser);
    const param = { 'interests': interests };
    const self = this;
    return new Promise(function (resolve, reject) {
      self.firestore.collection(Constants.Collection_Volunteer_Profile).doc(userId).update(param).then(function () {
        self.auth.user.interests = interests;
        self._currentUser = self.auth.user;
        resolve();
      }, function (err) {
        reject(err);
      });
    });
  }

  updateUserGoalsByProfile(goals: number[], userId: string): Promise<void> {
    // console.log('User:', updatedUser);
    const param = { 'goals': goals };
    const self = this;
    return new Promise(function (resolve, reject) {
      self.firestore.collection(Constants.Collection_Volunteer_Profile).doc(userId).update(param).then(function () {
        self.auth.user.goals = goals;
        self._currentUser = self.auth.user;
        resolve();
      }, function (err) {
        reject(err);
      });
    });
  }

  updateUserProfileImage(id: string, profileImage: string) {
    return this.firestore.collection(Constants.Collection_Volunteer_Profile).doc(id).update({
      'profileImgUrl': profileImage
    });
  }

  createRecommendedPartner(partner: any): any {
    console.log('Partner', partner);
    const param = JSON.parse(JSON.stringify(partner));
    const self = this;
    return new Promise<firebase.firestore.DocumentReference>(function (resolve, reject) {
      self.firestore.collection('partner-recommendation').add(param).then(doc => {
        //self._currentPartner = partner;
        resolve(doc);
      }, function (error) {
        reject(error);
      });
    });
  }

  createTransaction(transaction: Transaction) {
    //console.log(user);
    const param = JSON.parse(JSON.stringify(transaction));
    return this.firestore.collection(Constants.Collection_Donation_User_Mapping).add(param);
  }

  fetchUserDonationAmount(): any {
    let totalAmount: number = 0;
    const self = this;
    return new Promise<number>(function (resolve, reject) {
      self.firestore.collection(Constants.Collection_Donation_User_Mapping).ref.where('userID', '==', self.auth.uid).get().then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          if (doc.data().paymentMode.responseMessage === 'succeeded')
            totalAmount += doc.data().transactionAmount;
        });
        resolve(totalAmount);
      }, function (error) {
        reject(error);
      });
    });

  }

  fetchLatLongFromZipcode(zipcode) {
    if (zipcode) {
      this.sendPostRequest(zipcode).subscribe(
        res => {
          console.log(res);
          if (res && res.results[0]) {
            let lat = res.results[0].geometry.location.lat;
            let lng = res.results[0].geometry.location.lng;

            if (lat != 42.2781493 && lng != -83.8082647) {
              console.log("Fetched lat lng from Zipcode ******* lat :" + lat + " " + "  lng : " + lng);
              this.currentLat = lat;
              this.currentLng = lng;
              this.storage.set('reverse_zipcode', 'done');
            }
            else{
              console.log("Fetched lat lng from Zipcode ******* Zero Result ");
            }
          }
          else {
            console.log("Fetched lat lng from Zipcode ******* Zero Result ");
          }

        }
      );
    }
  }

  sendPostRequest(data: any): Observable<any> {
    return this.httpClient.post<any>('https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyC4thGCu0frCkjLq3pxE3eJl_32GYVb6yA&components=postal_code:' + data, "");
  }
  convertUpcomingEventsToCalendarCompatible(eventsArray) {
    let eventCalendarCompatibleArray = [];
    return new Promise<any>(function (resolve, reject) {
      for (let k = 0; k < eventsArray.length; k++) {
        let obj = {};
        if (eventsArray[k].rrule) {

          obj['title'] = eventsArray[k].name;
          obj['groupId'] = eventsArray[k].id;
          obj['rrule'] = eventsArray[k].rrule;
          eventCalendarCompatibleArray.push(obj);
        }
        else if (eventsArray[k].isRepeated && eventsArray[k].repeat != 'self-paced' && eventsArray[k].repeat != 'Once') {
          if (eventsArray[k].slotArr && eventsArray[k].slotArr.length > 0) {
            let freq: any;
            let byweekday = [];
            if (eventsArray[k].durationPeriod === 'week') {
              freq = RRule.WEEKLY;
            }
            else if (eventsArray[k].durationPeriod === 'month') {
              freq = RRule.MONTHLY;
            }
            else if (eventsArray[k].durationPeriod === 'year') {
              freq = RRule.YEARLY;
            }
            for (let i = 0; i < eventsArray[k].repeat.length; i++) {
              if (eventsArray[k].repeat[i] === 'Sunday') {
                byweekday.push(RRule.SU);
              }
              else if (eventsArray[k].repeat[i] === 'Monday') {
                byweekday.push(RRule.MO);
              }
              else if (eventsArray[k].repeat[i] === 'Tuesday') {
                byweekday.push(RRule.TU);
              }
              else if (eventsArray[k].repeat[i] === 'Wednesday') {
                byweekday.push(RRule.WE);
              }
              else if (eventsArray[k].repeat[i] === 'Thursday') {
                byweekday.push(RRule.TH);
              }
              else if (eventsArray[k].repeat[i] === 'Friday') {
                byweekday.push(RRule.FR);
              }
              else if (eventsArray[k].repeat[i] === 'Saturday') {
                byweekday.push(RRule.SA);
              }
            }
            const rule = new RRule({
              freq: freq,
              interval: eventsArray[k].duration,
              byweekday: byweekday,
              dtstart: (eventsArray[k].date),
              until: (eventsArray[k].repeatEndDate)
            })

            obj['title'] = eventsArray[k].name;
            obj['groupId'] = eventsArray[k].id;
            obj['rrule'] = rule.toString();
            eventCalendarCompatibleArray.push(obj);
          }
          else {
            let freq = RRule.WEEKLY;
            let byweekday = [];

            for (let i = 0; i < eventsArray[k].repeat.length; i++) {
              if (eventsArray[k].repeat[i] === 'Sunday') {
                byweekday.push(RRule.SU);
              }
              else if (eventsArray[k].repeat[i] === 'Monday') {
                byweekday.push(RRule.MO);
              }
              else if (eventsArray[k].repeat[i] === 'Tuesday') {
                byweekday.push(RRule.TU);
              }
              else if (eventsArray[k].repeat[i] === 'Wednesday') {
                byweekday.push(RRule.WE);
              }
              else if (eventsArray[k].repeat[i] === 'Thursday') {
                byweekday.push(RRule.TH);
              }
              else if (eventsArray[k].repeat[i] === 'Friday') {
                byweekday.push(RRule.FR);
              }
              else if (eventsArray[k].repeat[i] === 'Saturday') {
                byweekday.push(RRule.SA);
              }
            }
            const rule = new RRule({
              freq: freq,
              interval: eventsArray[k].minimumSlotDuration,
              byweekday: byweekday,
              dtstart: (eventsArray[k].startTime),
              until: (eventsArray[k].repeatEndDate)
            })

            obj['title'] = eventsArray[k].name;
            obj['groupId'] = eventsArray[k].id;
            obj['rrule'] = rule.toString();
            eventCalendarCompatibleArray.push(obj);
          }
        }
        else if (eventsArray[k].repeat[0] === 'Once') {
          if (eventsArray[k].slotArr && eventsArray[k].slotArr.length > 0) {

            for (let i = 0; i < eventsArray[k].slotArr.length; i++) {
              let obj1 = {};
              obj1['title'] = eventsArray[k].name;
              obj1['groupId'] = eventsArray[k].id;
              // obj1['start'] = eventsArray[k].slotArr[i].startTime.toDate().toISOString();
              // obj1['end'] = eventsArray[k].slotArr[i].endTime.toDate().toISOString();

              let st;
              let et;
              try {
                obj1['start']  = eventsArray[k].slotArr[i].startTime.toDate().toISOString();
                obj1['end']  = eventsArray[k].slotArr[i].endTime.toDate().toISOString();
              }
              catch (error) {
                obj1['start']  = eventsArray[k].slotArr[i].startTime.toISOString();
                obj1['end']  = eventsArray[k].slotArr[i].endTime.toISOString();
              }
              eventCalendarCompatibleArray.push(obj1);
            }
          }
          else {
            obj['title'] = eventsArray[k].name;
            obj['groupId'] = eventsArray[k].id;
            obj['start'] = eventsArray[k].startTime.toDate().toISOString();
            obj['end'] = eventsArray[k].endTime.toDate().toISOString();
          }

        }
        else if (eventsArray[k].repeat[0] === 'self-paced') {
          let diff: number = (moment(eventsArray[k].endTime)).diff(moment(eventsArray[k].date), 'days');
          if (diff > 0) {
            for (let i = 0; i < (diff + 1); i++) {
              obj = {};
              obj['title'] = eventsArray[k].name;
              obj['groupId'] = eventsArray[k].id;
              obj['date'] = moment(eventsArray[k].date).add(i, 'days').format('YYYY-MM-DD');
              eventCalendarCompatibleArray.push(obj);
            }
          }
          else if (diff == 0) {
            obj['title'] = eventsArray[k].name;
            obj['groupId'] = eventsArray[k].id;
            obj['date'] = moment(eventsArray[k].date).format('YYYY-MM-DD');
            eventCalendarCompatibleArray.push(obj);
          }
        }
      }
      if (eventCalendarCompatibleArray.length > 0) {
        resolve(eventCalendarCompatibleArray);
      }
      else {
        reject("Opps something went wrong while performing convertUpcomingEventsToCalendarCompatible");
      }
    });
  }

  check_data_event_activists_record(pid : string, date : Date) {
    const self = this;
      return new Promise<any>(function (resolve, reject) {
        self.firestore.collection(Constants.Collection_Event_Activists_Record).ref
          .where('date', '==', (date))
          .where('eventID', '==', pid).where('isDeleted', '==', false)
          .get()
          .then(function (querySnapshot) {
            if (querySnapshot.size > 0) {
              
              //resolve("DATAPRESENT");
              let ev = [];
              querySnapshot.forEach(function (doc) {
                let obj = doc.data();
                obj['selfID'] = doc.id;
                ev.push(obj);
              });
              resolve(ev);
            }
            else {
              reject("NoDATA");
            }
          }, function (error) {
            reject(error);
          });
      });
  }

  adding_data_event_activists_record(data: EventVolunteerRecord) {

    const paramEvent = Object.assign({}, data);
    return this.firestore.collection(Constants.Collection_Event_Activists_Record).add(paramEvent);
  }

  update_data_event_activists_record(data: EventVolunteerRecord) {
    const paramEvent = Object.assign({}, data);
    return this.firestore.collection(Constants.Collection_Event_Activists_Record).doc(data['selfID']).update(paramEvent);
  }

  isSIOPresentInMappingTable(name : string): any {
    const self = this;
    let ngoId : string ='';
    let obj ={};
    if(name && name != ''){
    return new Promise<any>(function (resolve, reject) {
      self.firestore.collection(Constants.Collection_NGO).ref.where('subDomainID', '==', name).get().then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          if (doc.data())
          obj['ngoId'] = doc.id;
          obj['isSubDomain'] = true;
          // obj['ngoName'] = doc.data().name;
          // obj['subDomainID'] = name;
        });
        if(obj && obj['ngoId']){
        resolve(obj);
        }
        else{
          reject('NoSuchData');
        }
      }, function (error) {
        console.log('Error while fetching subdomain from ngo'+error);
        reject('NoSuchData');
        
      });
    });
  }
  else{
    return new Promise<string>(function (resolve, reject) {
      reject("NoSuchData");
  });

  }
}

  fetchKeyAgainstUID(uid){
    const self = this;
    let id : string ='';
    return new Promise<string>(function (resolve, reject) {
      self.firestore.collection(Constants.Collection_Volunteer_Profile).ref.where('uid', '==', uid).get().then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          if (doc.data()){
            id = doc.id;
          }
        });
        if(id && id != ''){
        resolve(id);
        }
        else{
          reject('NoSuchData');
        }
      }, function (error) {
        reject("NoSuchData");
      });
    });
  }
}
