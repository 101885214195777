import { Component, QueryList, ViewChildren, OnInit } from '@angular/core';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Platform, IonRouterOutlet, Events, AlertController,ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Auth } from './services/auth/auth';
import { User } from './model/User';
import { faceBookAuthConfig } from '../environments/environment';
import { DataService } from './services/data.service';
import { Subscription } from 'rxjs';
import { AlertService } from './shared/services/alert/alert.service';
import { OverlayEventDetail } from '@ionic/core';
import { LoginModalPage } from './login-modal/login-modal.page';
import { Constant } from './common/constants';
import { Constants } from './shared/utils/constants';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {
  user: User;
  signoutFlag: boolean = false;
  public appMenu = [
    { title: 'Home', url: '/home/discover', icon: 'ios-home' },
    { title: 'My Events', url: '/home/myopportunities', icon: 'ios-time' },
    { title: 'About', url: '/about', icon: 'help' },
    // { title: 'Logout', url: '/login' },
    { title: 'Feedback', url: '/feedback', icon: 'ios-thumbs-up' },
  ];

  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;
  navLinksArray = [];
  public unsubscribeBackEvent: any;
  previousURL: string = '';
  stopFlag: boolean = false;
  routerSub: Subscription;
  pageSelected : string = '';
  disableDonation : boolean = false;

  routingArrayPass = ['/home/donate',
    // '/home/myopportunities',
    '/about',
    '/feedback',
    '/home/profile',
    '/editprofile',
    '/partner-profile',
    '/details?flow',
    '/campaign?campaignMappingId'];



  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    public events: Events,
    private auth: Auth,
    private storage: Storage,
    public alertController: AlertController,
    public dataservice: DataService,
    private alertService: AlertService,
    private modalController: ModalController,) {

    this.routerSub = router.events.subscribe(event => {
      
      if (!this.stopFlag) {
        if (event instanceof NavigationEnd) {
          // this.previousUrl = this.currentUrl;
          // this.currentUrl = event.url;
          console.log("Previous URL" + event.url);
          this.previousURL = event.url;
          this.initializeApp();
        }
        else if (event instanceof NavigationStart) {
          if (event.url === '/') {
            this.previousURL = 'home/discover';
          }
          else {
            let urlFoundinArray = false;
            for (let k = 0; k < this.routingArrayPass.length; k++) {
              if (event.url.includes(this.routingArrayPass[k])) {
                this.previousURL = event.url;
                urlFoundinArray = true;
              }
            }
            if (!urlFoundinArray) {
              this.previousURL = 'home/discover';
            }

          }
          this.initializeApp();
        }
      }
    });

    // this.initializeApp();
  }

  ngOnInit() {
    this.fbLibrary();
  }

  menuClicked(menu) {
    if (menu.title === 'Logout') {
      this.auth.logoutUser();
    }
   else if (menu.title === 'About') {
      this.pageSelected = 'About';
      this.router.navigateByUrl(menu.url)
    }
    if (menu.title == 'My Events') {
      this.pageSelected = 'My Events';
      if(this.auth.isAnonymousUser){
        this.openModal();
      }
      else{
        this.dataservice.volunteerScheduleEvents = 'upcoming';
        this.router.navigateByUrl(menu.url)
      }
    }
    else if (menu.title == 'Feedback') {
      this.pageSelected = 'Feedback';
      if(this.auth.isAnonymousUser){
        this.openModal();
      }
      else{
        this.router.navigateByUrl(menu.url)
      }
    }
  }

  async openModal() {
    const modal: HTMLIonModalElement =
      await this.modalController.create({
        component: LoginModalPage,
        componentProps: {
          
        }
      });

      modal.onDidDismiss().then((detail: OverlayEventDetail) => {
        const self = this;
        if (detail.data !== undefined && detail.data !== null) {
          if(this.pageSelected ==='My Events'){
          self.auth.nextPage = 'home/myopportunities';
          }
          else if(this.pageSelected ==='Feedback'){
            self.auth.nextPage = 'feedback';
            }
          //this.navController.navigateForward(['login-modal'], detail.data);
          if(detail.data ==='login'){
            if(this.pageSelected ==='My Events'){
              this.dataservice.volunteerScheduleEvents = 'upcoming';
              this.dataservice.nextPageFromMyOpportunity = 'home/discover';
            }
            this.router.navigate(['login'], { replaceUrl: true })
            }
          else if(detail.data ==='signup'){
            if(this.pageSelected ==='My Events'){
              this.dataservice.volunteerScheduleEvents = 'upcoming';
              this.dataservice.nextPageFromMyOpportunity = 'home/discover';
            }
            this.router.navigate(['signup-initial'], { replaceUrl: true })
            }
        }
        else{
          //this.router.navigate(['home/discover'], { replaceUrl: true })
        }
      });

    await modal.present();
  }

  navigateToProfile() {
    this.router.navigate(['home/profile']);
  }


  initializeApp() {
    const self = this;
    // self.dataservice.idOfParticularSIO = '';
    if(self.dataservice.idOfParticularSIO && self.dataservice.idOfParticularSIO != ''){
      self.disableDonation = true;
    }
    self.routerSub.unsubscribe();
    self.stopFlag = true;
    this.platform.ready().then(() => {
      self.dataservice.processResolveLatLng();
      if (this.auth.isAuthenticated) {
        this.events.subscribe('user', (user, time) => {
          this.user = user;
        });
      }
      console.log('app ready');
      self.statusBar.styleDefault();
      self.storage.get('first_time').then((val) => {
        if (val !== null) {
          self.storage.get('isSignupDone').then((val1) => {
            if (val1 != null && val1 == true) {
              console.log('app isnt for the first time started');
              self.auth.fireAuth.authState.subscribe(
                (user) => {
                  if (user) {
                    if (!self.auth.isAnonymousUser) {
                      self.auth.uid = user.uid;

                      console.log("I am in first condition");
                      if (self.auth.firstTimeSignup) {
                        self.signoutFlag = true;
                        self.router.navigate(['/signup']);
                      }
                      else {
                        self.auth.firstTimeSignup = false;
                        self.auth.checkUserExists(user.email).then(function (doc) {
                          if (doc === 'Intial Data found') {
                            self.signoutFlag = true;
                            self.router.navigate(['signup'], { replaceUrl: true });
                          }
                          else if (doc === 'Full Data found') {
                            self.signoutFlag = true;
                            localStorage.setItem('isLoggedIn', 'true');
                            //self.router.navigate(['home/discover'], { replaceUrl: true });
                            self.router.navigate([self.previousURL], { replaceUrl: true });
                          }
                        }, function (error) {
                          console.log('Error while getting user data.', error);
                          if (error == 'No data found for given email.') {
                            self.signoutFlag = true;
                            self.auth.firstTimeSignup = false;
                            self.router.navigate(['signup'], { replaceUrl: true });
                          }
                          else {
                           
                            self.alertService.showError("Error","The username/password entered does not match our records","Ok",null)
                          }
                        });
                      }
                    }
                    else {
                      //self.router.navigate(['home/discover'], { replaceUrl: true });
                    }
                    self.splashScreen.hide();
                  } else {
                    // if (self.signoutFlag) {
                    //   self.router.navigate(['login'], { replaceUrl: true });
                    //   self.splashScreen.hide();
                    // }
                    // else {
                    //   self.router.navigate(['signup-initial'], { replaceUrl: true });
                    //   self.splashScreen.hide();
                    // }
                    self.router.navigate(['login'], { replaceUrl: true });
                    self.splashScreen.hide();
                  }
                }
              );
            }
            else {
              self.router.navigate(['intro-page'], { replaceUrl: true });
              self.splashScreen.hide();
            }
          });
        } else {
          // self.router.navigate(['availabilitycheck']);
          self.splashScreen.hide();
          console.log('probably the first time');
          self.auth.fireAuth.authState.subscribe(
            (user) => {

              if(user && user.isAnonymous){
              self.dataservice.fetchKeyAgainstUID(user.uid).then(key =>{
                self.auth.anonymousUserID = key;
              }).catch(error =>{
                console.log("Error while fetching key from volunteer-user-profile against UID");
              })
            }

            if(user){
              //self.signoutFlag = true;
            }
              self.dataservice.idOfParticularSIO = '';
              //const domain = Constants.Base_SIO_URL;
              const domain = window.location.hostname;
              let subdomain = '';
              const d = domain.split('.');
              if (d.length > 0) {
                subdomain = d[0];
              }
              console.log('subdomain', subdomain);

              if (subdomain != 'app') {
                self.dataservice.isSIOPresentInMappingTable(subdomain).then(doc => {
                  console.log(doc);
                  if (doc && doc['ngoId'] && doc['ngoId'] != '') {
                    self.dataservice.idOfParticularSIO = doc['ngoId'];
                    self.dataservice.isSubDomainPresent = doc ['isSubDomain'];
                    // self.dataservice.nameOfParticularSIO = doc['ngoName'];
                    // self.dataservice.subDomainOfParticularSIO = doc['subDomainID'];
                    //self.router.navigate(['home/discover'], { replaceUrl: true });
                    self.proceedFurther(user);
                  }
                  else {
                    console.log('There is no such SIO in DB');
                    self.proceedFurther(user);
                  }
                },
                  error => {
                    console.log(error.message);
                    if(error ==='NoSuchData'){
                      
                      self.proceedFurther(user);
                      
                    }
                  });
      
              }
              else{
                self.proceedFurther(user);
              }


              
            }
          );
        }
      }).catch(error => console.log(error));
    });
  }

  proceedFurther(user){
    const self = this;
    if (user) {
      // if(!self.auth.isAnonymousUser && user.email){
      if (!user.isAnonymous) {
        self.auth.uid = user.uid;
        console.log("I am in second condition");
        if (self.auth.firstTimeSignup) {
          self.signoutFlag = true;
          self.router.navigate(['/signup']);
        }
        else {

          self.auth.firstTimeSignup = false;
          //self.router.navigate(['home/discover'], { replaceUrl: true });

          self.auth.checkUserExists(user.email).then(function (doc) {
            if (doc === 'Intial Data found') {
              self.signoutFlag = true;
              self.auth.firstTimeSignup = false;
              self.router.navigate(['signup'], { replaceUrl: true });
            }
            else if (doc === 'Full Data found') {
              self.signoutFlag = true;
              self.auth.firstTimeSignup = false;
              //self.router.navigate(['home/discover'], { replaceUrl: true });
              if (self.previousURL.includes('partner-profile?flow=DonationFlow&ngoMappingId')) {
                self.auth.directURL = self.previousURL;
                let idArry = self.previousURL.split('=')
                let navigationExtras = {
                  queryParams: {
                    flow: 'DonationFlow',
                    ngoMappingId: idArry[idArry.length - 1]
                  }
                };
                localStorage.setItem('isLoggedIn', 'true');
                self.router.navigate(['partner-profile'], navigationExtras);
              }
              //Below code for direct event link
              else if (self.previousURL.includes('details?flow=EventFlow&eventMappingId')) {
                self.auth.directURL = self.previousURL;
                let idArry = self.previousURL.split('=')
                let navigationExtras = {
                  queryParams: {
                    flow: 'EventFlow',
                    eventMappingId: idArry[idArry.length - 1]
                  }
                };
                localStorage.setItem('isLoggedIn', 'true');
                self.router.navigate(['details'], navigationExtras);
              }

              else if (self.previousURL.includes('campaign?campaignMappingId')) {
                self.auth.directURL = self.previousURL;
                let idArry = self.previousURL.split('=')
                let navigationExtras = {
                  queryParams: {
                    campaignMappingId: idArry[idArry.length - 1]
                  }
                };
                localStorage.setItem('isLoggedIn', 'true');
                self.router.navigate(['campaign'], navigationExtras);
              }

              else {
                localStorage.setItem('isLoggedIn', 'true');
                self.router.navigate([self.previousURL], { replaceUrl: true });
              }
            }

          }, function (error) {
            console.log('Error while getting user data.', error);
            if (error == 'No data found for given email.') {
              self.signoutFlag = true;
              self.auth.firstTimeSignup = false;
              self.router.navigate(['signup'], { replaceUrl: true });
            }
            else {                       
              self.alertService.showError("Error","The username/password entered does not match our records","Ok",null)
               
            }
          });
        }
      }
      else {
        //self.router.navigate(['home/discover'], { replaceUrl: true });
        self.prePopulatingAnonumousData();
        if (!self.auth.checkIsHomePageNotShown) {
          if (self.previousURL.includes('partner-profile?flow=DonationFlow&ngoMappingId')) {
            self.auth.directURL = self.previousURL;
            let idArry = self.previousURL.split('=')
            let navigationExtras = {
              queryParams: {
                flow: 'DonationFlow',
                ngoMappingId: idArry[idArry.length - 1]
              }
            };
            localStorage.setItem('isLoggedIn', 'true');
            self.router.navigate(['partner-profile'], navigationExtras);
          }
          else if (self.previousURL.includes('details?flow=EventFlow&eventMappingId')) {
            self.auth.directURL = self.previousURL;
            let idArry = self.previousURL.split('=')
            let navigationExtras = {
              queryParams: {
                flow: 'EventFlow',
                eventMappingId: idArry[idArry.length - 1]
              }
            };
            localStorage.setItem('isLoggedIn', 'true');
            self.router.navigate(['details'], navigationExtras);
          }

          else if (self.previousURL.includes('campaign?campaignMappingId')) {
            self.auth.directURL = self.previousURL;
            let idArry = self.previousURL.split('=')
            let navigationExtras = {
              queryParams: {
                campaignMappingId: idArry[idArry.length - 1]
              }
            };
            localStorage.setItem('isLoggedIn', 'true');
            self.router.navigate(['campaign'], navigationExtras);
          }

          else {
            localStorage.setItem('isLoggedIn', 'true');
            self.router.navigate([self.previousURL], { replaceUrl: true });
          }
          self.auth.checkIsHomePageNotShown = false;
        }
      }

    }
    else {
      if (self.signoutFlag) {
        self.router.navigate(['login'], { replaceUrl: true });
        self.splashScreen.hide();
      }
      else {

        if (self.previousURL.includes('partner-profile?flow=DonationFlow&ngoMappingId')) {
          self.auth.skipIntroPage = true;
          self.auth.directURL = self.previousURL;
        }
        else if (self.previousURL.includes('details?flow=EventFlow&eventMappingId')) {
          self.auth.skipIntroPage = true;
          self.auth.directURL = self.previousURL;
        }

        else if (self.previousURL.includes('campaign?campaignMappingId')) {
          self.auth.skipIntroPage = true;
          self.auth.directURL = self.previousURL;
        }
     

        self.router.navigate(['intro-page'], { replaceUrl: true });
        self.splashScreen.hide();
        

      }
    }
  }



  fbLibrary() {

    (window as any).fbAsyncInit = function () {
      window['FB'].init({
        appId: faceBookAuthConfig.appId,
        cookie: true,
        xfbml: true,
        version: 'v3.1'
      });
      window['FB'].AppEvents.logPageView();
    };

    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

  }

  // async presentAlertConfirm() {
  //   const alert = await this.alertController.create({
  //     header: 'Error',
  //     message: 'The username/password entered does not match our records',
  //     buttons: [
  //       {
  //         text: 'Ok',
  //         handler: () => {
  //           console.log('Confirm Ok');

  //         }
  //       }
  //     ]
  //   });

  //   await alert.present();
  // }

  prePopulatingAnonumousData() {
    this.auth.isAnonymousUser = true;
    //this.auth.user.interests = [0, 1, 2, 3, 4, 5, 6, 7];
  }

}
