import { Deserializable } from './deserializable.model';
import * as moment from 'moment';
import * as firebase from 'firebase/app';
import { Constants } from '../utils/constants';
import * as _ from 'lodash';

export class EventVolunteerRecord implements Deserializable {

  
  date: Date;
  endDate: Date;
  startDate : Date;
  repeat  = [];
  slot_record = [];
  eventID : string = '';
  selfID : string = '';
  

  

  deserialize(input: any): this {
    const tempThis = Object.assign(this, input);
    if (tempThis.date) {
      tempThis.date = tempThis.date.toDate();
      tempThis.startDate = tempThis.startDate.toDate();
      tempThis.endDate = tempThis.endDate.toDate();
    }
    if(input.eventType ==='self-paced'){
      tempThis.duration = input.duration;
    }
    return tempThis;
  }
}

export interface SlotDetail{
  date : Date;
  userSigned : number;
  startTime : Date;
  endTime : Date;
  maxVolunteer : number;

  // deserialize(input: any): this {
  //   const tempThis = Object.assign(this, input);
  //   if (tempThis.date && tempThis.startTime && tempThis.endTime) {
  //     tempThis.date = tempThis.date.toDate();
  //     tempThis.startTime = tempThis.startTime.toDate();
  //     tempThis.endTime = tempThis.endTime.toDate();
  //   }
  //   return tempThis;
  // }

}

  
