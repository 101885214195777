import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { User } from 'src/app/model/User';
import { Events, ModalController } from '@ionic/angular';
import { Constants } from '../../shared/utils/constants';
import { OverlayEventDetail } from '@ionic/core';
import { LoginModalPage } from 'src/app/login-modal/login-modal.page';
import { Router } from '@angular/router';


@Injectable({ providedIn: 'root' })
export class Auth {
   email: string;
   authState: any = null;
   user: User = new User();
   currentUserId: string;
   firstTimeSignup : boolean = false;
   uid : string = '';
   isAnonymousUser : boolean = false;
   skipIntroPage : boolean = false;
   directURL : string = '';
   dataToProcessFromLoginModal = {};
   anonymousUserID : string;
   nextPage : string = '';
   checkIsHomePageNotShown : boolean = false;
   flagAfterRefreshingPageInAnonymous : boolean = false;

  constructor(public fireAuth: AngularFireAuth,
    private firestore: AngularFirestore,
    public events: Events,
    private modalController:ModalController,
    private router: Router) {

      this.fireAuth.authState.subscribe((auth) => {
        this.authState = auth;
      });
      this.getCurrentUser();
    }

    getUserData(email: string) {
      console.log(email);
      this.userfilterByemail(email).subscribe(data => {
        if (data) {
          this.user = data[0] as User;
         // console.log(this.user);
          this.events.publish('user', this.user, 1);
        }
    });
  }

    // Returns true if user is logged in
  isAuthenticated(): boolean {
    return this.authState !== null;
  }

  getCurrentUser() {
    this.fireAuth.auth.onAuthStateChanged( user => {
      if (user) {
        this.email = user.email;
        this.getUserData(this.email);
      }
    });

    return this.email;
  }

  /**
   * Login an user
   *
   * @param {string} email  The email to use for user.
   * @param {string} password  The user password.
   * @returns the processed Promise.
   */
  loginUser(email: string, password: string): any {
    return this.fireAuth.auth.signInWithEmailAndPassword(email, password);
  }
  /**
   * Sign Up an user
   *
   * @param {string} email  The email to use for user.
   * @param {string} password  The user password.
   * @returns the processed Promise.
   */
  signupUser(email: string, password: string): any {
    return this.fireAuth.auth.createUserWithEmailAndPassword(email, password);
  }

  createUser(user: User) {
    //console.log(user);
    const param = JSON.parse(JSON.stringify(user));
    return this.firestore.collection(Constants.Collection_Volunteer_Profile).add(param);
  }

  addAdditionalUserData(id : string ,user: User) {
    const param = JSON.parse(JSON.stringify(user));
    return this.firestore.collection(Constants.Collection_Volunteer_Profile).doc(id).update(param);
  }

  userfilterByemail(categoriaToFilter: string) {
    return this.firestore.collection(Constants.Collection_Volunteer_Profile, ref => ref.where('email', '==', categoriaToFilter )).valueChanges();
  }

  // Sign up with email/password
  SignUp(email, password) {
    return this.fireAuth.auth.createUserWithEmailAndPassword(email, password)
      .then((result) => {
        console.log(result.user);
      }).catch((error) => {
        console.log(error.message);
      });
  }

  /**
   * Reset Password
   *
   * @param {string} email  The email to use for user.
   * @returns the processed Promise.
   */
  resetPassword(email: string): any {
    return this.fireAuth.auth.sendPasswordResetEmail(email);
  }

  /**
   * Logout User
   *
   * @returns the processed Promise.
   */
  logoutUser(): any {
    return this.fireAuth.auth.signOut();
  }

  checkUserExists(email: string): any {
    const self = this;
    return new Promise<string>(function(resolve, reject) {
      self.firestore.collection('volunteer-user-profile').ref.where('email', '==', email).get().then(
        function (doc) {
          if (doc.size > 0) {
            doc.forEach(function (doc2) {
              if(doc2.data().uid && doc2.data().email && (doc2.data().firstName || doc2.data().name)){
                self.user = doc2.data() as User;
                resolve('Full Data found');
              }
              else{
                resolve('Intial Data found');
              }
              
            });
          } else {
            reject('No data found for given email.');
          }
        }
      , function(error) {
        reject(error);
      });
    });
  }

  checkDuplicateUser(email: string): any {
    const self = this;
    return new Promise<string>(function(resolve, reject) {
      self.firestore.collection('volunteer-user-profile').ref.where('email', '==', email).get().then(
        function (doc) {
          if (doc.size > 0) {
            doc.forEach(function (doc2) {
              console.log("User Exist with ID :" + doc2.id);
                resolve(doc2.id);
              
              
            });
          } else {
            console.log("User does not exits");
            reject('Not Found');
          }
        }
      , function(error) {
        reject(error);
      });
    });
  }

  getUserObject(email: string): any {
    const self = this;
    return new Promise<User>(function(resolve, reject) {
      self.firestore.collection('volunteer-user-profile').ref.where('email', '==', email).get().then(
        function (doc) {
          if (doc.size > 0) {
            doc.forEach(function (doc) {
              const userData = (doc.data()) as User
            resolve(userData);
            });
          } 
          else{
            reject("No Data Found");
          }
        }
      , function(error) {
        reject(error);
      });
    });
  }

  addWarningUserData(id : string) {
    let obj = {}
    obj['status'] = true;
    obj['createdDate'] = new Date();
    return this.firestore.collection(Constants.Collection_Volunteer_Profile).doc(id).update(
      {
        warningAccepted: obj
      }
    );
  }

  async showLoginPage(dataToProcess:any) {
    const self = this;
    self.dataToProcessFromLoginModal = dataToProcess;
    const modal: HTMLIonModalElement =
      await this.modalController.create({
        component: LoginModalPage,
        componentProps: {

        }
      });

    modal.onDidDismiss().then((detail: OverlayEventDetail) => {
      const self = this;
      self.nextPage = 'donate-stripe-payment';
      if (detail.data !== undefined && detail.data !== null) {
        if (detail.data === 'login') {
          this.router.navigate(['login'], { replaceUrl: false })
        }
        else if (detail.data === 'signup') {
          this.router.navigate(['signup-initial'], { replaceUrl: false })
        }
      }
    });

    await modal.present();
  }

  setUser(user){
    this.user = user;
  }


}

