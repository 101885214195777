import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minDuration'
})
export class MinDurationPipe implements PipeTransform {

  transform(value: number, args?: any): string {

    return value === 0 ? 'Full Event Duration' : value > 1 ? value + ' hours' : value + ' hour';
  }

}
