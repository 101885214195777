export class Constants {

    static dateFormat = 'MM/DD/YYYY';
    
    static Collection_Volunteer_Profile = 'volunteer-user-profile';
    static Collection_Scheduled_Event = 'scheduled-event';
    static Collection_Volunteer_User_Event = 'volunteer-user-event';
    static Collection_Volunteer_User_Notify = 'volunteer-user-notify';
    static Collection_Donation_User_Mapping = 'donation-user-mapping';
    static Collection_Event_Activists_Record = 'event-activists-record';
    static Collection_NGO_VOLUNTEER = 'ngo-volunteer-user';
    static Collection_NGO = 'ngo';
    //static Base_SIO_URL = 'habitatkalamazoo.backpac.co';
    //static Base_SIO_URL = 'rotary.backpac.co';
    //static Base_SIO_URL = 'sittest.web.app';
    static Base_SIO_URL = 'communitywarehouse.backpac.co';
}


