import * as moment from 'moment';
export class Utils {
    /*
       Definitions:                                                           :::
//:::    South latitudes are negative, east longitudes are positive           :::
//:::                                                                         :::
//:::  Passed to function:                                                    :::
//:::    lat1, lon1 = Latitude and Longitude of point 1 (in decimal degrees)  :::
//:::    lat2, lon2 = Latitude and Longitude of point 2 (in decimal degrees)  :::
//:::    unit = the unit you desire for results                               :::
//:::           where: 'M' is statute miles (default)                         :::
//:::                  'K' is kilometers                                      :::
//:::                  'N' is nautical miles
    */



    static distance(lat1, lon1, lat2, lon2, unit) {
        if ((lat1 == lat2) && (lon1 == lon2)) {
            return 0;
        } else {
            const radlat1 = Math.PI * lat1 / 180;
            const radlat2 = Math.PI * lat2 / 180;
            const theta = lon1 - lon2;
            const radtheta = Math.PI * theta / 180;
            let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
            if (dist > 1) {
                dist = 1;
            }
            dist = Math.acos(dist);
            dist = dist * 180 / Math.PI;
            dist = dist * 60 * 1.1515;
            if (unit === 'K') { dist = dist * 1.609344; }
            if (unit === 'N') { dist = dist * 0.8684; }
            return dist;
        }
    }

    // static causeImg = [ 
    //     'assets/img/Animals.svg',
    //     'assets/img/Arts.svg',
    //     'assets/img/Children.svg',
    //     'assets/img/Community.svg',
    //     'assets/img/Education.svg',
    //     'assets/img/Environment.svg',
    //     'assets/img/Health Medicine.svg',
    //     'assets/img/Homeless.svg',
    //     'assets/img/Hunger.svg',
    //     'assets/img/LGBTQ.svg',
    //     'assets/img/Poverty.svg',
    //     'assets/img/Seniors.svg',
    //     'assets/img/Sports.svg',
    //     'assets/img/Veterans.svg'];

    // static causeLabel = [ 
    //     'Animals',
    //     'Arts',
    //     'Children',
    //     'Community',
    //     'Education',
    //     'Environment',
    //     'Health Medicine',
    //     'Homeless',
    //     'Hunger',
    //     'LGBTQ',
    //     'Poverty',
    //     'Seniors',
    //     'Sports',
    //     'Veterans'];

  
        static causeImg = [ 
            'assets/img/causes/Union.svg' ,
            'assets/img/causes/homeless.svg',
            'assets/img/causes/lgbt.svg',
            'assets/img/causes/education.svg',
            'assets/img/causes/envr.svg',
            'assets/img/causes/elder.svg',
            'assets/img/causes/veterans.svg',
            'assets/img/causes/landslide.svg',
            'assets/img/causes/animal.svg',
            'assets/img/causes/art.svg',
            'assets/img/causes/gender.svg',
            'assets/img/causes/hunger.svg'];
    
        static causeLabel = [ 
            'Social justice',
            'Homelessness',
            'LGBTQ+',
            'Education',
            'Environment',
            'Seniors',
            'Veterans',
            'Disaster Relief',
            'Animals',
            'Art & Culture',
            'Gender equality',
            'Hunger'
        ];

        static causeObjArr = [{
                'id':0,
                'label': 'Social justice',
                'url': 'assets/img/causes/Union.svg'
            },
            {
                'id': 1,
                'label': 'Homelessness',
                'url': 'assets/img/causes/homeless.svg'
            },
            {
                'id': 2,
                'label': 'LGBTQ+',
                'url': 'assets/img/causes/lgbt.svg'
            },
            {
                'id': 3,
                'label': 'Education',
                'url': 'assets/img/causes/education.svg'
            },
            {
                'id': 4,
                'label': 'Environment',
                'url': 'assets/img/causes/envr.svg'
            },
            {
                'id': 5,
                'label': 'Seniors',
                'url': 'assets/img/causes/elder.svg'
            },
            {
                'id': 6,
                'label': 'Veterans',
                'url': 'assets/img/causes/veterans.svg'
            },
            {
                'id': 7,
                'label': 'Disaster Relief',
                'url': 'assets/img/causes/landslide.svg'
            },
            {
                'id': 8,
                'label': 'Animals',
                'url': 'assets/img/causes/animal.svg'
            },
            {
                'id': 9,
                'label': 'Art & Culture',
                'url': 'assets/img/causes/art.svg'
            },
            {
                'id': 10,
                'label': 'Gender equality',
                'url': 'assets/img/causes/gender.svg'
            },
            {
                'id': 11,
                'label': 'Hunger',
                'url': 'assets/img/causes/hunger.svg'
            }
        ]




        static goalsImg = [
            'assets/img/SDG_Icons_Inverted_Transparent_WEB-01.png',
            'assets/img/SDG_Icons_Inverted_Transparent_WEB-02.png',
            'assets/img/SDG_Icons_Inverted_Transparent_WEB-03.png',
            'assets/img/SDG_Icons_Inverted_Transparent_WEB-04.png',
            'assets/img/SDG_Icons_Inverted_Transparent_WEB-05.png',
            'assets/img/SDG_Icons_Inverted_Transparent_WEB-06.png',
            'assets/img/SDG_Icons_Inverted_Transparent_WEB-07.png',
            'assets/img/SDG_Icons_Inverted_Transparent_WEB-08.png',
            'assets/img/SDG_Icons_Inverted_Transparent_WEB-09.png',
            'assets/img/SDG_Icons_Inverted_Transparent_WEB-10.png',
            'assets/img/SDG_Icons_Inverted_Transparent_WEB-11.png',
            'assets/img/SDG_Icons_Inverted_Transparent_WEB-12.png',
            'assets/img/SDG_Icons_Inverted_Transparent_WEB-13.png',
            'assets/img/SDG_Icons_Inverted_Transparent_WEB-14.png',
            'assets/img/SDG_Icons_Inverted_Transparent_WEB-15.png',
            'assets/img/SDG_Icons_Inverted_Transparent_WEB-16.png',
            'assets/img/SDG_Icons_Inverted_Transparent_WEB-17.png'
        ];

    static validateEmail(mail: string): boolean {
        return (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
    }

    static isValidUSZip(zipCode: string): boolean {
        return /^\d{5}(-\d{4})?$/.test(zipCode)
    }

    static formatDate(date: Date, format:string): string {
        return moment(date).format(format);
    }
    
    static getTimeForServer(date: any, timezone: string): moment.Moment {
        if (moment.isMoment(date)) {
            return moment.tz(date.format('YYYY-MM-DD HH:mm'), timezone);
        }
        else if (date instanceof String) {
            return moment.tz(date, timezone);
        }
        else if (date instanceof Date) {
            return moment.tz(moment(date).format('YYYY-MM-DD HH:mm'), timezone);
        }
        return moment.tz(date, timezone);
    }


}
