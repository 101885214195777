import { Component, OnInit } from '@angular/core';
import { QRScanner, QRScannerStatus } from '@ionic-native/qr-scanner/ngx';
import { ModalController, NavController } from '@ionic/angular';

@Component({
  selector: 'app-qrcode',
  templateUrl: './qrcode.page.html',
  styleUrls: ['./qrcode.page.scss'],
})
export class QrcodePage implements OnInit {

  result : string = '';
  constructor(private qrScanner: QRScanner,
    private navCtrl: NavController,private modalController: ModalController,) { }

  ngOnInit() {



  }

  ionViewDidEnter() {
    this.thereShouldBeAMethodHere() 
    // document.querySelector('#my-select').shadowRoot.querySelector('.inner-scroll').setAttribute('style', 'background:transparent');
  }

  back() {
    this.qrScanner.destroy();
    this.modalController.dismiss();
  }

  thereShouldBeAMethodHere() {
    // Optionally request the permission early
    // this.qrScanner.prepare()
    //   .then((status: QRScannerStatus) => {
    //     if (status.authorized) {
    //       // camera permission was granted


          // start scanning
          document.getElementsByTagName('div')[0].style.opacity = "0";
          let scanSub = this.qrScanner.scan().subscribe((text: string) => {
            console.log('Scanned something', text);
            this.result = text['result'];
            this.closeModal(true);

            this.qrScanner.hide(); // hide camera preview
            scanSub.unsubscribe(); // stop scanning
          });

      //   } else if (status.denied) {
      //     // camera permission was permanently denied
      //     // you must use QRScanner.openSettings() method to guide the user to the settings page
      //     // then they can grant the permission from there
      //   } else {
      //     // permission was denied, but not permanently. You can ask for permission again at a later time.
      //   }
      // })
      // .catch((e: any) => console.log('Error is', e));
   }

   async closeModal(done: boolean) {
    const onClosedData = this.result;
    if (done) {
      if(onClosedData && onClosedData  != ''){
      await this.modalController.dismiss(onClosedData);
      }
      
    } else {
      await this.modalController.dismiss();
    }
  }

}

