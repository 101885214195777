export class PledgeUserMapping {
  id: string = '';
  campaignId: string = '';
  user: PledgeUser = new PledgeUser();
  pledgeId : string = '';
  status: number = 0;
  updated_on : Date;
  deadline : Date;
  signature_goal: number = 0;
  email:string = '';
  phone:Number=0;
  pledgeAmount:Number;
  members:string = '';
}

export class PledgeUser {
  id:string = ''
  firstName:string = ''
  lastName:string = ''
}
  

