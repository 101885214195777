import { Component, OnInit } from '@angular/core';
import { AlertService } from './../../services/alert/alert.service';
import {AlertController, ModalController, NavParams} from '@ionic/angular';
import { Utils } from '../../utils/utils';

@Component({
  selector: 'app-development-goals',
  templateUrl: './development-goals.page.html',
  styleUrls: ['./development-goals.page.scss'],
})
export class DevelopmentGoalsPage implements OnInit {

  goalsImg = Utils.goalsImg;
  noOfRows = 0;
  rowCountArray = [0, 2, 4, 6, 8, 10, 12, 14, 16];
  selectedGoals = [];
  selectedGoals1=[];
  profileGoalSaveButton:string = ' ';
  constructor(private modalController: ModalController,  public alertController: AlertController,
    private navParams: NavParams , private alertSrvc:AlertService) 
  {}

  

  ngOnInit() {
    this.noOfRows = Math.floor(this.goalsImg.length / 2);
    this.selectedGoals1 = this.navParams.get('selectedGoals');
    this.selectedGoals = Object.assign([], this.selectedGoals1);
    
    if(this.navParams.get('saveBooleanButton'))
    {
      this.profileGoalSaveButton = this.navParams.get('saveBooleanButton');
    }
    console.log(this.selectedGoals);
  }
  isSelectedIcon(index) {
    return this.selectedGoals.indexOf(index) !== -1;
  }

  getSelectionIconName(index) {
    return this.isSelectedIcon(index) ? "checkmark-circle" : "radio-button-off";
  }

  getSelectionStyleClass(index) {
    return this.isSelectedIcon(index) ? "selected hydrated" : "unselected hydrated";
  }

  async closeModal(done: boolean) {
    const onClosedData = this.selectedGoals;
    if (done) {
      if(onClosedData.length > 0){
      await this.modalController.dismiss(onClosedData);
      }
      else{
      
      this.alertSrvc.showWarning("Oh, no!",' You forgot to add your goal',"Ok",null)
    
      }
    } else {
      await this.modalController.dismiss();
    }
  }

   toggleSelection(data) {
    console.log(data);
    const index = this.selectedGoals.indexOf(data);
    if ( index === -1) {
      this.selectedGoals.push(data);
    } else {
      this.selectedGoals.splice(index, 1);
    }

    console.log(this.selectedGoals);
  }

  back(){
    this.modalController.dismiss(this.selectedGoals1);
  }
 
  // async closeModal(){
  //   const onClosedData = this.selectedGoals;
  //   if(onClosedData.length > 0) {
  //     await this.modalController.dismiss(onClosedData);
  //   } else {
  //     this.presentAlert()
  //   }
  
  // }
  // async presentAlert() {
  //   const
  //     alert = await this.alertController.create({
  //       header: 'Alert!',
  //       message: 'Please select any UN Sustainable Development Goal',
  //       backdropDismiss: false,
  //       buttons: [
  //         {
  //           text: 'Okay',
  //           handler: () => {
  //             console.log('Confirm Okay');
  //           }
  //         }
  //       ]
  //     });

  //   await alert.present();
  // }

}
