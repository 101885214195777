import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Event, SearchContext } from '../../models/event.model';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Constants } from '../../utils/constants';
import { Utils } from '../../utils/utils';
import * as moment from 'moment';
import { Events } from '@ionic/angular';
import { User } from 'src/app/model/User';
import { Auth } from '../../../services/auth/auth';
import * as _ from 'lodash';
import { EventVolunteerRecord } from '../../models/event_volunteer_record.model';
import { RRule, RRuleSet, rrulestr } from 'rrule';
import { DataService } from 'src/app/services/data.service';



@Injectable({
  providedIn: 'root'
})

export class EventService {
  
  searchContext: SearchContext = new SearchContext();
  recurringEvents = new Map<string, Event[]>();
  oneTimeEvents = new Map<string, Event[]>();
  event: Event;
  currentLat: Number;
  currentLng: Number;
  maxDateForCurrentResult: Date;
  slotDetails : EventVolunteerRecord;

  recurringEventsForTemplate = new Map<string, Event[]>();
  oneTimeEventsForTemplate = new Map<string, Event[]>();
  allEventsForTemplate = new Map<string, Event[]>(); 
  sortedEventsOnallEventsForTemplate : Event[]= [];
  partnerEventsOnallEventsForTemplate : Event[]= [];
  filteredEventsForRecommend : Event[]= [];
  eventTypeSeeMore : string = '';
  joinThePackSlots = [];
  dataAlreadyFetched : boolean = false;
  eventTypeForUpdatingCachedEvents : string = '';
  allEventsArray : Event[]= [];
  allEventsArrayForFilterScreen : Event[]= [];
  filterEventsOnallEventsForTemplate : Event[]= [];
  fetchDataForDate : Date = new Date();
  tempAllEventsArray : Event[]= [];
  cacheEvents : Event [] = [];


  oneTimeEventsForTemplateForRecommend = new Map<string, Event[]>();
  oneTimeEventsForRecommend = new Map<string, Event[]>();
  allEventsForTemplateForRecommend = new Map<string, Event[]>();
  allEventsArrayForRecommend : Event[]= [];
  dataAlreadyFetchedForRecommend : boolean = false;
  cacheEventsForRecommend : Event [] = [];
  fetchDataForDateForRecommend : Date = new Date();
  tempAllEventsArrayForRecommend : Event[]= [];
  sortedEventsOnallEventsForTemplateForRecommend : Event[]= [];

  constructor(private firestore: AngularFirestore,
    private geolocation: Geolocation,
    private eventPublisher: Events,
    private auth: Auth,
    private dataService : DataService) {
    this.geolocation.getCurrentPosition().then((resp) => {
      this.currentLat = resp.coords.latitude;
      this.currentLng = resp.coords.longitude;
    }).catch((error) => {
      console.log('Error getting location', error);
    });


    const watch = this.geolocation.watchPosition();
    watch.subscribe((data) => {
      // data can be a set of coordinates, or an error (if an error occurred).
      if (data.coords) {
        this.currentLat = data.coords.latitude;
        this.currentLng = data.coords.longitude;
      }
    });

    //this.getRecurringEvents();
  }

  saveSearchContext(date:string, startTime, endTime, distance, interests, campaignCode,days) {
    this.searchContext.date = date;
    this.searchContext.distance = distance;
    this.searchContext.interests = interests;
    this.searchContext.campaignCode = campaignCode;
    this.searchContext.days = days;
  }

  resetSearchContext() {
    if (this.searchContext.date !== '') {
      this.oneTimeEvents.clear();
    }
    this.searchContext.reset()
  }

  reloadAllEvents() {
    // this.oneTimeEvents.clear();
    console.log('Reload Home Screen: reloadAllEvents()')
    this.eventPublisher.publish('reloadEventsList');
  }

  getOneTimeEvents(date?: Date) {
    const self = this;
    this.oneTimeEvents.clear();
    this.oneTimeEventsForRecommend.clear();
    self.updateLatLngFromDataService();
    // return this.firestore.collection(FirebaseConstants.Collection_Scheduled_Event).snapshotChanges();
    return this.firestore.collection(Constants.Collection_Scheduled_Event, ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      if (this.searchContext.date !== '') {
        const startDateQuery = moment(this.searchContext['date']).startOf('date').toDate();
        const endDateQuery = moment(this.searchContext['date']).endOf('date').toDate();
          query.where('startTime', '>=', startDateQuery)
          .where('startTime', '<=', endDateQuery)
          .where('isRepeated', '==', false).where('isDeleted', '==', false)
          .orderBy('startTime');
      } else {
        const defaultDate = date ? date : new Date();
        this.maxDateForCurrentResult = moment(defaultDate).add(15, 'days').endOf('date').toDate();
        query = query.where('isRepeated', '==', false)
          .where('startTime', '>=', defaultDate)
          .where('startTime', '<=', this.maxDateForCurrentResult).where('isDeleted', '==', false)
          .orderBy('startTime');
      }

      //Changes starts for Sio Event functionality
      if(this.dataService.idOfParticularSIO && this.dataService.idOfParticularSIO != ''){
        query = query.where('ngo.id', '==', this.dataService.idOfParticularSIO )
      }
      //Changes ends for Sio Event functionality
      return query;
    }).get().toPromise();
  }

  selectedEvent(event: Event) {
    this.event = event;
  }

  private getFBQueryForSearchContext(query:firebase.firestore.CollectionReference | firebase.firestore.Query) {
    let searchQuery = query;
    if (this.searchContext.isFilterEnabled) {
      if(this.searchContext.date !== '') {
        const startDateQuery = moment(this.searchContext['date']).startOf('date').toDate();
        const endDateQuery = moment(this.searchContext['date']).endOf('date').toDate();
        searchQuery = searchQuery.where('startTime', '>=', startDateQuery)
        searchQuery = searchQuery.where('startTime', '<=', endDateQuery)
      }
      
      // if(this.searchContext.campaignCode !== '') {
      //   searchQuery = searchQuery.where('campaign.code', '==', this.searchContext.campaignCode)
      //   searchQuery = searchQuery.where('campaign.status', '==', 'Started')
      // }

      // if (this.searchContext["interests"].length > 0) {
      //   searchQuery = searchQuery.where('causes', 'array-contains', this.searchContext["interests"])
      // }
    }
    return searchQuery
  }

  getRecurringEvents() : any{
    const self = this;
    const startDate = new Date();
    self.updateLatLngFromDataService();
    self.recurringEventsForTemplate = new Map<string, Event[]>();
    self.allEventsForTemplate = new Map<string, Event[]>();
    const recurringEvents = this.firestore.collection(Constants.Collection_Scheduled_Event, ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.where('repeatEndDate', '>', startDate).where('isRepeated', '==', true).where('isDeleted', '==', false).orderBy('repeatEndDate');

      //Changes starts for Sio Event functionality
      if(this.dataService.idOfParticularSIO && this.dataService.idOfParticularSIO != ''){
        query = query.where('ngo.id', '==', this.dataService.idOfParticularSIO )
      }
      //Changes ends for Sio Event functionality
      return query;
    }).snapshotChanges();
    recurringEvents.subscribe(data => {
      self.recurringEvents.clear();
      self.recurringEventsForTemplate = new Map<string, Event[]>();
      data.forEach(function (e) {

        if (e.type === 'added') {
          //console.log('++++ Event Added: ' + e.payload.doc.id);
          const event = new Event().deserialize(e.payload.doc.data());
          event.distance = event.lat !== 0 && event.lng !== 0 ?
            Utils.distance(self.currentLat, self.currentLng, event.lat, event.lng, 'M') : -1;
          event.pid = e.payload.doc.id;
          event['selfID'] = e.payload.doc.id;
          event.repeat.forEach(function (day: string) {
            let arr = self.recurringEvents.get(day);
            if (!arr) {
              arr = [];
            }
            arr.push(event);
            self.recurringEvents.set(day, arr);
            
          });
          //Template Changes started

          let arrTemplate = self.recurringEventsForTemplate.get(event.templateEventId);
          if (!arrTemplate) {
            arrTemplate = [];
          }
          arrTemplate.push(event);
          self.recurringEventsForTemplate.set(event.templateEventId, arrTemplate);
          
           //Template Changes end
        }
        if (e.type === 'modified') {
          //console.log('%%%%%% Event modified: ' + e.payload.doc.id);
          const newData = e.payload.doc.data();
          const event = new Event().deserialize(newData);
          event.distance = event.lat !== 0 && event.lng !== 0 ?
            Utils.distance(self.currentLat, self.currentLng, event.lat, event.lng, 'M') : -1;
          event.pid = e.payload.doc.id;
          event.repeat.forEach(function (day: string) {
            const arr = self.recurringEvents.get(day);
            if (!arr) {
              return;
            }
            const newArr = arr.map(function (oldEvent) {
              if (oldEvent.pid === event.pid) {
                return event;
              }
              return oldEvent;
            });
            self.recurringEvents.set(day, arr);
          });

        }
        if (e.type === 'removed') {
          //console.log('------ Event removed: ' + e.payload.doc.id);
          const repeat = e.payload.doc.data()['repeat'];
          const pid = e.payload.doc.id;
          repeat.forEach(function (day: string) {
            const arr = self.recurringEvents.get(day);
            if (!arr) {
              return;
            }
            const newArr = arr
              .filter(function (oldEvent) {
                if (oldEvent.pid !== pid) {
                  return oldEvent;
                }
              });
            self.recurringEvents.set(day, arr);
          });
        }

      });
      //self.allEventsForTemplate = self.recurringEventsForTemplate;
      //self.allEventsForTemplate = Object.assign({},self.recurringEventsForTemplate);
      self.allEventsForTemplate = _.cloneDeep(self.recurringEventsForTemplate);
      self.allEventsForTemplateForRecommend = _.cloneDeep(self.recurringEventsForTemplate);
      //console.log('###### Total recurring event loaded: ',self.recurringEvents);
      return null;
    });
  }

  getAllEventsForDate(date: Date,eventType : string,alreadyfetchedEvent ?): Promise<Event[]> {
    console.log('Get all events', date);
    const self = this;
    

    return new Promise(async function (resolve, reject) {
      let t = self.proceedWithGetCombineEvents(date,eventType,alreadyfetchedEvent);
      // if(t && t.length > 0){
      //   resolve(t);
      // }
      // else{
      //   reject(null);
      // }
      t.then(data =>{
        if(data){
         if(eventType === 'Upcoming'){
            self.cacheEvents = [];
         }
          else if(eventType === 'Recommend'){
            self.cacheEventsForRecommend = [];
          }
          resolve(data)
        }
        else{
          reject(null);
        }
      }).catch(error =>{
        //reject(null);
        if(error && error['date'] && error['type'] && error['tempEventArray']){
          resolve(self.getAllEventsForDate(error['date'], error['type'], error['tempEventArray']));
        }
      })
    });
  }

  async proceedWithGetCombineEvents(date,eventType,alreadyfetchedEvent) : Promise<any>{
    const self = this;
    self.updateLatLngFromDataService();
    self.fetchDataForDate = date;
    const distance = +this.searchContext.distance;
    const interests = this.searchContext.interests;
    const campaignCode = this.searchContext.campaignCode;
    const days = this.searchContext.days;

    const dt = (this.searchContext.date !== '') ? moment(this.searchContext.date).toDate() : date ? date : new Date();

    const contextDate = moment(dt);

    const dateStr = contextDate.format(Constants.dateFormat);

    let respArr = [];
    return new Promise(async function (resolve, reject) {
    self.oneTimeEvents.clear();
    self.oneTimeEventsForTemplate.clear();
    if(eventType === 'Upcoming'){
      
      if (!self.maxDateForCurrentResult || contextDate.isAfter(moment(self.maxDateForCurrentResult)) || self.oneTimeEvents.size === 0) {
        await self.getOneTimeEvents(contextDate.toDate())
          .then(function (docs) {
            self.oneTimeEventsForTemplate = new Map<string, Event[]>();
            self.oneTimeEvents  = new Map<string, Event[]>();
            docs.forEach(function (doc) {
              const event = new Event().deserialize(doc.data());
              event['selfID'] = doc.id;
              //console.log('****** One time event added: '+contextDate.toDate(),event)
              event.distance = event.lat !== 0 && event.lng !== 0 ?
                Utils.distance(self.currentLat, self.currentLng, event.lat, event.lng, 'M') : -1;
              event.pid = doc.id;
              const tempDateStr = moment(event.startTime).format(Constants.dateFormat);
              let arr = self.oneTimeEvents.get(tempDateStr);
              if (!arr) {
                arr = [];
              }
              arr.push(event);
              self.oneTimeEvents.set(tempDateStr, arr);
              //Template Changes starts
              //*******Below code will take all one time events against template ID************
              let arrTemplate = self.oneTimeEventsForTemplate.get(event.templateEventId);
              if (!arrTemplate) {
                arrTemplate = [];
              }
              arrTemplate.push(event);
              self.oneTimeEventsForTemplate.set(event.templateEventId, arrTemplate);

              //*******Below code will take all repeated time events against template ID************
              let temp = self.allEventsForTemplate.get(event.templateEventId);
              if (!temp) {
                temp = [];
              }
              temp.push(event);
              self.allEventsForTemplate.set(event.templateEventId, temp);
              self.oneTimeEventsForTemplate.clear();
              //Template Changes ends
            });
          }).catch(er =>{
            console.log('Error while fetching one time events',er);
          })
        }
        //console.log('****** Total one time events: '+self.oneTimeEvents.size,self.oneTimeEvents);
        //console.log('****** Total one time Template events: '+self.oneTimeEventsForTemplate.size,self.oneTimeEventsForTemplate);

        let tempAllEventsForTemplate = new Map<string, Event[]>(); 
        self.allEventsForTemplate.forEach((value: Event[], key: string) => {
          //console.log(key, value);
          const sorted : Event[] = value.sort((t1, t2) => {
            if (moment(t1.startTime).isAfter(moment(t2.startTime))) { return 1; }
            if (moment(t1.startTime).isBefore(moment(t2.startTime))) { return -1; }
            return 0;
          });
          tempAllEventsForTemplate.set(key,sorted);
      });
      
      self.allEventsForTemplate = _.cloneDeep(tempAllEventsForTemplate);

        self.allEventsForTemplate.forEach((value: Event[], key: string) => {
          //console.log(key, value);
          for(let i = 0 ; i < value.length; i++){
          const firstEventOFEachKey : Event = value[i];
            if(firstEventOFEachKey.repeat && firstEventOFEachKey.repeat.length > 0 
              && firstEventOFEachKey.repeat[0] =='Once' 
              && moment(firstEventOFEachKey.endTime).isSameOrAfter(new Date())){
                respArr.push(firstEventOFEachKey);
            }
            else if (firstEventOFEachKey.repeat && firstEventOFEachKey.repeat.length > 0 
                && firstEventOFEachKey.repeat[0] !='Once' 
                && moment(firstEventOFEachKey.repeatEndDate).isSameOrAfter(new Date())){
              respArr.push(firstEventOFEachKey);
            }
          }
      });


      //*************************below code for filter screen******************** */

      let tpRespArr = _.cloneDeep(respArr);

      let tmpFilter = [];
      for(let j = 0; j < tpRespArr.length; j++){
        let ev = tpRespArr[j];
        if(self.allEventsArrayForFilterScreen && self.allEventsArrayForFilterScreen.length > 0){
          let flag = false;
          for(let t = 0; t < self.allEventsArrayForFilterScreen.length; t++){
            
            if(ev['selfID'] === self.allEventsArrayForFilterScreen[t]['selfID']){
              flag = true;
            }
          }
          if(!flag){
            tmpFilter.push(ev);
          }
        }
        else{
          tmpFilter.push(ev);
        }
      }

      let finalFilterArray = [];
        for(let k = 0; k < tmpFilter.length; k ++){
          let ev = tmpFilter[k];
          let groupList =  [];
          if(self.auth.user &&  self.auth.user.eventGroupList){
            groupList = self.auth.user.eventGroupList;
          }
          let result = [];

          if(ev['eventGroupList'] && ev['eventGroupList'].length > 0){
            result = groupList.filter(function(item){ return ev['eventGroupList'].indexOf(item) > -1});   
            // return (result.length > 0); 
          }

          if(result && result.length > 0){
            ev.isPrivate = true;
            finalFilterArray.push(ev);
          }
          else if(ev && (!ev['eventGroupList'] || ev['eventGroupList'].length == 0)){
            finalFilterArray.push(ev);
          }
          else if(ev['eventGroupList'] == undefined){
            finalFilterArray.push(ev);
          }
        }

      self.allEventsArrayForFilterScreen = self.allEventsArrayForFilterScreen.concat(finalFilterArray);

      //******************************* END************************************** */

      let tt = [];
      for(let j = 0; j < respArr.length; j++){
        let ev = respArr[j];
        if(alreadyfetchedEvent && alreadyfetchedEvent.length > 0){
          let flag = false;
          for(let t = 0; t < alreadyfetchedEvent.length; t++){
            
            if(ev['selfID'] === alreadyfetchedEvent[t].selfID){
              flag = true;
            }
          }
          if(!flag){
            tt.push(ev);
          }
        }
        else{
          tt.push(ev);
        }
      }

      respArr = _.cloneDeep(tt);

      if(respArr.length > 0){

        self.allEventsArray = (_.cloneDeep(respArr));
        

        respArr = _.cloneDeep(self.applyFilterForRecommend(self.allEventsArray,'','Upcoming'));
        respArr = respArr.sort((t1, t2) => {
          if (moment(t1.startTime).isAfter(moment(t2.startTime))) { return 1; }
          if (moment(t1.startTime).isBefore(moment(t2.startTime))) { return -1; }
          return 0;
        });
        //self.sortedEventsOnallEventsForTemplate = [];
          self.dataAlreadyFetched = true;

//**********************************************Sorting******************************************* */

        const sorted_upcoming = [];
        let sorted_once = [];
        let sorted_repeat = [];
        let sorted_selfpaced = [];
        let sorted_virtual = [];

        //let tempArray = _.cloneDeep(respArr);
        let tempArray = (_.cloneDeep(respArr));

        for(let s = 0; s < tempArray.length; s++){
          let ev = tempArray[s] as Event
          let isGrpMatch : boolean= false;
          let anyPrivateEventFound = false;
          let groupList =  [];
          if(self.auth.user &&  self.auth.user.eventGroupList){
            groupList = self.auth.user.eventGroupList;
          }
          let result = [];

          if(ev.eventGroupList && ev.eventGroupList.length > 0){
            result = groupList.filter(function(item){ return ev.eventGroupList.indexOf(item) > -1});   
            // return (result.length > 0); 
          }

          if(result && result.length > 0){
            isGrpMatch = true;
            ev.isPrivate = true;
          }

          if(!isGrpMatch && ((!ev.eventGroupList) || (ev.eventGroupList && ev.eventGroupList.length == 0)) && ev.repeat && ev.repeat.length > 0 && ev.repeat[0] ==='Once' && !ev.isVirtual){
            //sorted_once.push(ev)
            if(self.dataService.isSubDomainPresent){
              if(alreadyfetchedEvent && alreadyfetchedEvent.length > 0){
                let flag = false;
                for(let t = 0; t < alreadyfetchedEvent.length; t++){
                  
                  if(ev['selfID'] === alreadyfetchedEvent[t].selfID){
                    flag = true;
                  }
                }
                if(!flag){
                  sorted_once.push(ev);
                }
              }
              else{
                sorted_once.push(ev);
              }
            }
            else if(ev.distance <= 50){
              if(alreadyfetchedEvent && alreadyfetchedEvent.length > 0){
                let flag = false;
                for(let t = 0; t < alreadyfetchedEvent.length; t++){
                  
                  if(ev['selfID'] === alreadyfetchedEvent[t].selfID){
                    flag = true;
                  }
                }
                if(!flag){
                  sorted_once.push(ev);
                }
              }
              else{
                sorted_once.push(ev);
              }
            }
          }
          else if(!isGrpMatch && ((!ev.eventGroupList) || (ev.eventGroupList && ev.eventGroupList.length == 0)) && ev.repeat && ev.repeat.length > 0 && ev.repeat[0] ==='self-paced' && !ev.isVirtual){
            //sorted_selfpaced.push(ev)
            if(self.dataService.isSubDomainPresent){
              if(alreadyfetchedEvent && alreadyfetchedEvent.length > 0){
                let flag = false;
                for(let t = 0; t < alreadyfetchedEvent.length; t++){
                  
                  if(ev['selfID'] === alreadyfetchedEvent[t].selfID){
                    flag = true;
                  }
                }
                if(!flag){
                  sorted_selfpaced.push(ev);
                }
              }
              else{
                sorted_selfpaced.push(ev);
              }
            }
            else if(ev.distance <= 50){
              if(alreadyfetchedEvent && alreadyfetchedEvent.length > 0){
                let flag = false;
                for(let t = 0; t < alreadyfetchedEvent.length; t++){
                  
                  if(ev['selfID'] === alreadyfetchedEvent[t].selfID){
                    flag = true;
                  }
                }
                if(!flag){
                  sorted_selfpaced.push(ev);
                }
              }
              else{
                sorted_selfpaced.push(ev);
              }
            }
          }
          else if(!isGrpMatch && ((!ev.eventGroupList) || (ev.eventGroupList && ev.eventGroupList.length == 0)) && ev.repeat && ev.repeat.length > 0 && ev.repeat[0] !='Once' && ev.repeat[0] !='self-paced' && !ev.isVirtual){
            //sorted_repeat.push(ev)
            if(self.dataService.isSubDomainPresent){
              if(alreadyfetchedEvent && alreadyfetchedEvent.length > 0){
                let flag = false;
                for(let t = 0; t < alreadyfetchedEvent.length; t++){
                  
                  if(ev['selfID'] === alreadyfetchedEvent[t].selfID){
                    flag = true;
                  }
                }
                if(!flag){
                  sorted_repeat.push(ev);
                }
              }
              else{
                sorted_repeat.push(ev);
              }
            }
            else if(ev.distance <= 50){
              if(alreadyfetchedEvent && alreadyfetchedEvent.length > 0){
                let flag = false;
                for(let t = 0; t < alreadyfetchedEvent.length; t++){
                  
                  if(ev['selfID'] === alreadyfetchedEvent[t].selfID){
                    flag = true;
                  }
                }
                if(!flag){
                  sorted_repeat.push(ev);
                }
              }
              else{
                sorted_repeat.push(ev);
              }
            }
          }
          else if(!isGrpMatch && ((!ev.eventGroupList) || (ev.eventGroupList && ev.eventGroupList.length == 0)) && ev.isVirtual){
            sorted_virtual.push(ev)
          }
        }

        
        // sorted_once = sorted_once.sort((t1, t2) => {
        //   if ((t1.distance) > (t2.distance)) { return 1; }
        //   if ((t1.distance) < (t2.distance)) { return -1; }
        //   return 0;
        // });
        
        // sorted_selfpaced = sorted_selfpaced.sort((t1, t2) => {
        //   if ((t1.distance) > (t2.distance)) { return 1; }
        //   if ((t1.distance) < (t2.distance)) { return -1; }
        //   return 0;
        // });

        let repeatTemp = []
        for( let q = 0; q < sorted_repeat.length; q++){
          let rruleString = sorted_repeat[q].rrule;
          let initialDate = moment(date);

          const endDate = moment(initialDate).add(15, 'days').endOf('date');

          if(rruleString) {

            try{
              //const eventrRule = RRule.fromString(rruleString);
              const eventrRule = rrulestr(rruleString);

              const occurance = eventrRule.between(initialDate.toDate(), endDate.toDate());

              if(occurance.length > 0) {
                repeatTemp.push(sorted_repeat[q]);
              }
            }
            catch(error){
              console.log("Error while converting rrule from rrulestring, So going to change rrule string to rruleset object",error);

              // const eventrRule = rrulestr(rruleString);
              
              

              // const occurance = eventrRule.between(initialDate.toDate(), endDate.toDate());

              // if(occurance.length > 0) {
              //   repeatTemp.push(sorted_repeat[q]);
              // }
            }
          }
        }

        sorted_repeat = _.cloneDeep(repeatTemp);

        // sorted_repeat = sorted_repeat.sort((t1, t2) => {
        //   if ((t1.distance) > (t2.distance)) { return 1; }
        //   if ((t1.distance) < (t2.distance)) { return -1; }
        //   return 0;
        // });

        let tempVirtual = [];
        let tempVirtualOnce = [];
        let tempVitualRepeat = [];
        let tempVirtualSP = [];
        for(let r = 0; r < sorted_virtual.length; r ++){
          let temp = sorted_virtual[r];
          if(temp.repeat && temp.repeat.length > 0 && temp.repeat[0] ==='Once'){
            if(alreadyfetchedEvent && alreadyfetchedEvent.length > 0){
              let flag = false;
              for(let t = 0; t < alreadyfetchedEvent.length; t++){
                
                if(temp.selfID === alreadyfetchedEvent[t].selfID){
                  flag = true;
                }
              }
              if(!flag){
                tempVirtualOnce.push(temp);
              }
            }
            else{
              tempVirtualOnce.push(temp);
            }
          }
          else if(temp.repeat && temp.repeat.length > 0 && temp.repeat[0] !='Once' && temp.repeat[0] !='self-paced'){
            let rruleString = temp.rrule;
            let initialDate = moment(date);

            const endDate = moment(initialDate).add(15, 'days').endOf('date');

            if(rruleString) {

              try{
                //const eventrRule = RRule.fromString(rruleString);
                const eventrRule = rrulestr(rruleString);

                const occurance = eventrRule.between(initialDate.toDate(), endDate.toDate());

                if(occurance.length > 0) {
                  if(alreadyfetchedEvent && alreadyfetchedEvent.length > 0){
                    let flag = false;
                    for(let t = 0; t < alreadyfetchedEvent.length; t++){
                      
                      if(temp.selfID === alreadyfetchedEvent[t].selfID){
                        flag = true;
                      }
                    }
                    if(!flag){
                      tempVitualRepeat.push(temp);
                    }
                  }
                  else{
                    tempVitualRepeat.push(temp);
                  }
                  
                  
                }
              }
              catch(error){
                console.log("Error while converting rrule from rrulestring, So going to change rrule string to rruleset object",error);
  
                // const eventrRule = rrulestr(rruleString);
                
                
  
                // const occurance = eventrRule.between(initialDate.toDate(), endDate.toDate());
  
                // if(occurance.length > 0) {
                //   if(alreadyfetchedEvent && alreadyfetchedEvent.length > 0){
                //     let flag = false;
                //     for(let t = 0; t < alreadyfetchedEvent.length; t++){
                      
                //       if(temp.selfID === alreadyfetchedEvent[t].selfID){
                //         flag = true;
                //       }
                //     }
                //     if(!flag){
                //       tempVitualRepeat.push(temp);
                //     }
                //   }
                //   else{
                //     tempVitualRepeat.push(temp);
                //   }
                  
                  
                // }
              }
  
            }
          }
          else if(temp.repeat && temp.repeat.length > 0 && temp.repeat[0] ==='self-paced'){
            //tempVirtual.push(temp);
            if(alreadyfetchedEvent && alreadyfetchedEvent.length > 0){
              let flag = false;
              for(let t = 0; t < alreadyfetchedEvent.length; t++){
                
                if(temp.selfID === alreadyfetchedEvent[t].selfID){
                  flag = true;
                }
              }
              if(!flag){
                tempVirtualSP.push(temp);
              }
            }
            else{
              tempVirtualSP.push(temp);
            }
          }
          
        }

        for(let w = 0; w < tempVirtualOnce.length; w ++){
          tempVirtual.push(tempVirtualOnce[w]);
        }
        for(let w = 0; w < tempVitualRepeat.length; w ++){
          tempVirtual.push(tempVitualRepeat[w]);
        }
        for(let w = 0; w < tempVirtualSP.length; w ++){
          tempVirtual.push(tempVirtualSP[w]);
        }

        sorted_virtual = _.cloneDeep(tempVirtual);

        // sorted_virtual = sorted_virtual.sort((t1, t2) => {
        //   if (moment(t1.startTime).isAfter(moment(t2.startTime))) { return 1; }
        //   if (moment(t1.startTime).isBefore(moment(t2.startTime))) { return -1; }
        //   return 0;
        // });


        for(let w = 0; w < sorted_once.length; w ++){
          sorted_upcoming.push(sorted_once[w]);
        }
        for(let w = 0; w < sorted_repeat.length; w ++){
          sorted_upcoming.push(sorted_repeat[w]);
        }
        for(let w = 0; w < sorted_selfpaced.length; w ++){
          sorted_upcoming.push(sorted_selfpaced[w]);
        }
        for(let w = 0; w < sorted_virtual.length; w ++){
          sorted_upcoming.push(sorted_virtual[w]);
        }

        self.sortedEventsOnallEventsForTemplate = self.sortedEventsOnallEventsForTemplate.concat(_.cloneDeep(sorted_upcoming));
        self.cacheEvents = self.cacheEvents.concat(_.cloneDeep(sorted_upcoming));
        if(self.cacheEvents.length > 4){ 
          self.tempAllEventsArray = self.tempAllEventsArray.concat(_.cloneDeep(sorted_upcoming));
          // return (sorted_upcoming);
          resolve(self.cacheEvents);
        }
        else{
          if(moment(self.fetchDataForDate).isSameOrBefore(moment(new Date()).add(6, 'M').toDate())){
        
            self.fetchDataForDate = moment(self.fetchDataForDate).add(15, 'days').endOf('date').toDate();

            self.tempAllEventsArray = self.tempAllEventsArray.concat(_.cloneDeep(sorted_upcoming));
            
            // self.proceedWithGetCombineEvents(self.fetchDataForDate,'Upcoming',self.tempAllEventsArray);
            let obj = {};
            obj['date'] = self.fetchDataForDate;
            obj['type'] = 'Upcoming';
            obj['tempEventArray'] = self.sortedEventsOnallEventsForTemplate;
            reject(obj);
          }
          else{
            self.tempAllEventsArray = self.tempAllEventsArray.concat(_.cloneDeep(sorted_upcoming));
            //self.sortedEventsOnallEventsForTemplate  = _.cloneDeep(self.tempAllEventsArray)
            resolve((self.cacheEvents));
          }
        }
        

    }
    else{
      if(moment(self.fetchDataForDate).isSameOrBefore(moment(new Date()).add(6, 'M').toDate())){
        
        self.fetchDataForDate = moment(self.fetchDataForDate).add(15, 'days').endOf('date').toDate()
        
        //self.proceedWithGetCombineEvents(self.fetchDataForDate,'Upcoming',self.allEventsArray.concat(_.cloneDeep(respArr)));
        let obj = {};
            obj['date'] = self.fetchDataForDate;
            obj['type'] = 'Upcoming';
            obj['tempEventArray'] = self.sortedEventsOnallEventsForTemplate;
            reject(obj);
      }

      else{
        //self.tempAllEventsArray = self.tempAllEventsArray.concat(_.cloneDeep(sorted_upcoming));
        resolve((self.cacheEvents));
      }

    }

//**********************************************Sorting******************************************* */
        //resolve(respArr);
    }
    else if(eventType === 'Recommend'){
      let respArrTemp : Event[] = [];
      let respArrFinal : Event[] = [];
      // let user : User = self.auth.user;
      // if(self.auth.isAnonymousUser){
      //   let intrst = [0,1,2,3,4,5,6,7];
      //   respArrTemp = _.cloneDeep(self.applyFilterForRecommend(self.sortedEventsOnallEventsForTemplate,intrst,'Recommend'));
      // }
      // else{
      // respArrTemp = _.cloneDeep(self.applyFilterForRecommend(self.sortedEventsOnallEventsForTemplate,user.interests,'Recommend'));
      // }
      
      // respArrFinal = respArrTemp.sort((t1, t2) => {
      //   if ((t1.distance) > (t2.distance)) { return 1; }
      //   if ((t1.distance) < (t2.distance)) { return -1; }
      //   return 0;
      // });
      // self.filteredEventsForRecommend = [];
      // self.filteredEventsForRecommend = _.cloneDeep(respArrFinal);
      // self.dataAlreadyFetched = true;
      // resolve(respArrFinal);

      if (!self.maxDateForCurrentResult || contextDate.isAfter(moment(self.maxDateForCurrentResult)) || self.oneTimeEvents.size === 0) {
        await self.getOneTimeEvents(contextDate.toDate())
          .then(function (docs) {
            self.oneTimeEventsForTemplateForRecommend = new Map<string, Event[]>();
            self.oneTimeEventsForRecommend  = new Map<string, Event[]>();
            docs.forEach(function (doc) {
              const event = new Event().deserialize(doc.data());
              event['selfID'] = doc.id;
              //console.log('****** One time event added: '+contextDate.toDate(),event)
              event.distance = event.lat !== 0 && event.lng !== 0 ?
                Utils.distance(self.currentLat, self.currentLng, event.lat, event.lng, 'M') : -1;
              event.pid = doc.id;
              const tempDateStr = moment(event.startTime).format(Constants.dateFormat);
              let arr = self.oneTimeEventsForRecommend.get(tempDateStr);
              if (!arr) {
                arr = [];
              }
              arr.push(event);
              self.oneTimeEventsForRecommend.set(tempDateStr, arr);
              //Template Changes starts
              //*******Below code will take all one time events against template ID************
              let arrTemplate = self.oneTimeEventsForTemplateForRecommend.get(event.templateEventId);
              if (!arrTemplate) {
                arrTemplate = [];
              }
              arrTemplate.push(event);
              self.oneTimeEventsForTemplateForRecommend.set(event.templateEventId, arrTemplate);

              //*******Below code will take all repeated time events against template ID************
              let temp = self.allEventsForTemplateForRecommend.get(event.templateEventId);
              if (!temp) {
                temp = [];
              }
              temp.push(event);
              self.allEventsForTemplateForRecommend.set(event.templateEventId, temp);
              self.oneTimeEventsForTemplateForRecommend.clear();
              //Template Changes ends
            });
          }).catch(er =>{
            console.log('Error while fetching one time events',er);
          })
        }
        //console.log('****** Total one time events: '+self.oneTimeEvents.size,self.oneTimeEvents);
        //console.log('****** Total one time Template events: '+self.oneTimeEventsForTemplate.size,self.oneTimeEventsForTemplate);

        let tempAllEventsForTemplate = new Map<string, Event[]>(); 
        self.allEventsForTemplateForRecommend.forEach((value: Event[], key: string) => {
          //console.log(key, value);
          const sorted : Event[] = value.sort((t1, t2) => {
            if (moment(t1.startTime).isAfter(moment(t2.startTime))) { return 1; }
            if (moment(t1.startTime).isBefore(moment(t2.startTime))) { return -1; }
            return 0;
          });
          tempAllEventsForTemplate.set(key,sorted);
      });
      
      self.allEventsForTemplateForRecommend = _.cloneDeep(tempAllEventsForTemplate);

        self.allEventsForTemplateForRecommend.forEach((value: Event[], key: string) => {
          //console.log(key, value);
          for(let i = 0 ; i < value.length; i++){
          const firstEventOFEachKey : Event = value[i];
            if(firstEventOFEachKey.repeat && firstEventOFEachKey.repeat.length > 0 
              && firstEventOFEachKey.repeat[0] =='Once' 
              && moment(firstEventOFEachKey.endTime).isSameOrAfter(new Date())){
                respArr.push(firstEventOFEachKey);
            }
            else if (firstEventOFEachKey.repeat && firstEventOFEachKey.repeat.length > 0 
                && firstEventOFEachKey.repeat[0] !='Once' 
                && moment(firstEventOFEachKey.repeatEndDate).isSameOrAfter(new Date())){
              respArr.push(firstEventOFEachKey);
            }
          }
      });

      let tt = [];
      for(let j = 0; j < respArr.length; j++){
        let ev = respArr[j];
        if(alreadyfetchedEvent && alreadyfetchedEvent.length > 0){
          let flag = false;
          for(let t = 0; t < alreadyfetchedEvent.length; t++){
            
            if(ev['selfID'] === alreadyfetchedEvent[t].selfID){
              flag = true;
            }
          }
          if(!flag){
            tt.push(ev);
          }
        }
        else{
          tt.push(ev);
        }
      }

      respArr = _.cloneDeep(tt);

      if(respArr.length > 0){

        self.allEventsArrayForRecommend = (_.cloneDeep(respArr));

        //respArr = _.cloneDeep(self.applyFilterForRecommend(self.allEventsArrayForRecommend,'','Upcoming'));
        respArr = _.cloneDeep(self.applyFilterForRecommend(self.allEventsArrayForRecommend,'','Recommend'));
        respArr = respArr.sort((t1, t2) => {
          if (moment(t1.startTime).isAfter(moment(t2.startTime))) { return 1; }
          if (moment(t1.startTime).isBefore(moment(t2.startTime))) { return -1; }
          return 0;
        });
        //self.sortedEventsOnallEventsForTemplateForRecommend = [];
          self.dataAlreadyFetchedForRecommend = true;

//**********************************************Sorting******************************************* */

        const sorted_recommend = [];
        let sorted_once = [];
        let sorted_repeat = [];
        let sorted_selfpaced = [];
        let sorted_virtual = [];
        let sorted_private = [];

        //let tempArray = _.cloneDeep(respArr);
        let tempArray = (_.cloneDeep(respArr));
        let email = self.auth.user ? self.auth.user.email : '';
        let ngoIdFromVolMappingTable = email ?  await self.fetchNgoId(email) : '';

        for(let s = 0; s < tempArray.length; s++){
          let ev = tempArray[s] as Event;
          let isGrpMatch : boolean= false;
          let anyPrivateEventFound = false;
          let groupList =  [];
          if(self.auth.user &&  self.auth.user.eventGroupList){
            groupList = self.auth.user.eventGroupList;
          }
          let result = [];
          
          if(ev.eventGroupList && ev.eventGroupList.length > 0){
            result = groupList.filter(function(item){ return ev.eventGroupList.indexOf(item) > -1});   
            // return (result.length > 0); 
          }

          if(result && result.length > 0 && self.auth.user.sio && self.auth.user.sio['id'] === ev.ngo.id){
            isGrpMatch = true;
          }

          if(isGrpMatch){
            //sorted_once.push(ev)
            anyPrivateEventFound = true;
            if(alreadyfetchedEvent && alreadyfetchedEvent.length > 0){
              let flag = false;
              for(let t = 0; t < alreadyfetchedEvent.length; t++){
                
                if(ev['selfID'] === alreadyfetchedEvent[t].selfID){
                  flag = true;
                }
              }
              if(!flag){
                ev.isPrivate = true;
                sorted_private.push(ev);
              }
            }
            else{
              ev.isPrivate = true;
              sorted_private.push(ev);
            }
          }

          else if(((!ev.eventGroupList) || (ev.eventGroupList && ev.eventGroupList.length == 0)) && ev.repeat && ev.repeat.length > 0 && ev.repeat[0] ==='Once' && !ev.isVirtual && !anyPrivateEventFound){
            //sorted_once.push(ev)
            if(self.dataService.isSubDomainPresent){
              if(alreadyfetchedEvent && alreadyfetchedEvent.length > 0){
                let flag = false;
                for(let t = 0; t < alreadyfetchedEvent.length; t++){
                  
                  if(ev['selfID'] === alreadyfetchedEvent[t].selfID){
                    flag = true;
                  }
                }
                if(!flag){
                  sorted_once.push(ev);
                }
              }
              else{
                sorted_once.push(ev);
              }
            }
            else if(ev.distance <= 50){
              if(alreadyfetchedEvent && alreadyfetchedEvent.length > 0){
                let flag = false;
                for(let t = 0; t < alreadyfetchedEvent.length; t++){
                  
                  if(ev['selfID'] === alreadyfetchedEvent[t].selfID){
                    flag = true;
                  }
                }
                if(!flag){
                  sorted_once.push(ev);
                }
              }
              else{
                sorted_once.push(ev);
              }
            }
          }
          else if(((!ev.eventGroupList) || (ev.eventGroupList && ev.eventGroupList.length == 0)) && ev.repeat && ev.repeat.length > 0 && ev.repeat[0] ==='self-paced' && !ev.isVirtual  && !anyPrivateEventFound){
            //sorted_selfpaced.push(ev)
            if(self.dataService.isSubDomainPresent){
              if(alreadyfetchedEvent && alreadyfetchedEvent.length > 0){
                let flag = false;
                for(let t = 0; t < alreadyfetchedEvent.length; t++){
                  
                  if(ev['selfID'] === alreadyfetchedEvent[t].selfID){
                    flag = true;
                  }
                }
                if(!flag){
                  sorted_selfpaced.push(ev);
                }
              }
              else{
                sorted_selfpaced.push(ev);
              }
            }
            else if(ev.distance <= 50){
              if(alreadyfetchedEvent && alreadyfetchedEvent.length > 0){
                let flag = false;
                for(let t = 0; t < alreadyfetchedEvent.length; t++){
                  
                  if(ev['selfID'] === alreadyfetchedEvent[t].selfID){
                    flag = true;
                  }
                }
                if(!flag){
                  sorted_selfpaced.push(ev);
                }
              }
              else{
                sorted_selfpaced.push(ev);
              }
            }
          }
          else if(((!ev.eventGroupList) || (ev.eventGroupList && ev.eventGroupList.length == 0)) && ev.repeat && ev.repeat.length > 0 && ev.repeat[0] !='Once' && ev.repeat[0] !='self-paced' && !ev.isVirtual  && !anyPrivateEventFound){
            //sorted_repeat.push(ev)
            if(self.dataService.isSubDomainPresent){
              if(alreadyfetchedEvent && alreadyfetchedEvent.length > 0){
                let flag = false;
                for(let t = 0; t < alreadyfetchedEvent.length; t++){
                  
                  if(ev['selfID'] === alreadyfetchedEvent[t].selfID){
                    flag = true;
                  }
                }
                if(!flag){
                  sorted_repeat.push(ev);
                }
              }
              else{
                sorted_repeat.push(ev);
              }
            }
            else if(ev.distance <= 50){
              if(alreadyfetchedEvent && alreadyfetchedEvent.length > 0){
                let flag = false;
                for(let t = 0; t < alreadyfetchedEvent.length; t++){
                  
                  if(ev['selfID'] === alreadyfetchedEvent[t].selfID){
                    flag = true;
                  }
                }
                if(!flag){
                  sorted_repeat.push(ev);
                }
              }
              else{
                sorted_repeat.push(ev);
              }
            }
          }
          else if(((!ev.eventGroupList) || (ev.eventGroupList && ev.eventGroupList.length == 0)) && ev.isVirtual && !anyPrivateEventFound){
            sorted_virtual.push(ev)
          }
        }

        
        // sorted_once = sorted_once.sort((t1, t2) => {
        //   if ((t1.distance) > (t2.distance)) { return 1; }
        //   if ((t1.distance) < (t2.distance)) { return -1; }
        //   return 0;
        // });
        
        // sorted_selfpaced = sorted_selfpaced.sort((t1, t2) => {
        //   if ((t1.distance) > (t2.distance)) { return 1; }
        //   if ((t1.distance) < (t2.distance)) { return -1; }
        //   return 0;
        // });

        let repeatTemp = []
        for( let q = 0; q < sorted_repeat.length; q++){
          let rruleString = sorted_repeat[q].rrule;
          let initialDate = moment(date);

          const endDate = moment(initialDate).add(15, 'days').endOf('date');

          if(rruleString) {
            
            try{
              //const eventrRule = RRule.fromString(rruleString);
              const eventrRule = rrulestr(rruleString);

              const occurance = eventrRule.between(initialDate.toDate(), endDate.toDate());

              if(occurance.length > 0) {
                repeatTemp.push(sorted_repeat[q]);
              }
            }
            catch(error){
              console.log("Error while converting rrule from rrulestring, So going to change rrule string to rruleset object",error);

              // const eventrRule = rrulestr(rruleString);
              
              

              // const occurance = eventrRule.between(initialDate.toDate(), endDate.toDate());

              // if(occurance.length > 0) {
              //   repeatTemp.push(sorted_repeat[q]);
              // }
            }
          }
        }

        sorted_repeat = _.cloneDeep(repeatTemp);

        // sorted_repeat = sorted_repeat.sort((t1, t2) => {
        //   if ((t1.distance) > (t2.distance)) { return 1; }
        //   if ((t1.distance) < (t2.distance)) { return -1; }
        //   return 0;
        // });

        let tempVirtual = [];
        let tempVirtualOnce = [];
        let tempVitualRepeat = [];
        let tempVirtualSP = [];
        for(let r = 0; r < sorted_virtual.length; r ++){
          let temp = sorted_virtual[r];
          if(temp.repeat && temp.repeat.length > 0 && temp.repeat[0] ==='Once'){
            if(alreadyfetchedEvent && alreadyfetchedEvent.length > 0){
              let flag = false;
              for(let t = 0; t < alreadyfetchedEvent.length; t++){
                
                if(temp.selfID === alreadyfetchedEvent[t].selfID){
                  flag = true;
                }
              }
              if(!flag){
                tempVirtualOnce.push(temp);
              }
            }
            else{
              tempVirtualOnce.push(temp);
            }
          }
          else if(temp.repeat && temp.repeat.length > 0 && temp.repeat[0] !='Once' && temp.repeat[0] !='self-paced'){
            let rruleString = temp.rrule;
            let initialDate = moment(date);

            const endDate = moment(initialDate).add(15, 'days').endOf('date');

            if(rruleString) {

              try{
                //const eventrRule = RRule.fromString(rruleString);
                const eventrRule = rrulestr(rruleString);

                const occurance = eventrRule.between(initialDate.toDate(), endDate.toDate());

                if(occurance.length > 0) {
                  if(alreadyfetchedEvent && alreadyfetchedEvent.length > 0){
                    let flag = false;
                    for(let t = 0; t < alreadyfetchedEvent.length; t++){
                      
                      if(temp.selfID === alreadyfetchedEvent[t].selfID){
                        flag = true;
                      }
                    }
                    if(!flag){
                      tempVitualRepeat.push(temp);
                    }
                  }
                  else{
                    tempVitualRepeat.push(temp);
                  }
                  
                  
                }
              }
              catch(error){
                console.log("Error while converting rrule from rrulestring, So going to change rrule string to rruleset object",error);
  
                // const eventrRule = rrulestr(rruleString);
                
                
  
                // const occurance = eventrRule.between(initialDate.toDate(), endDate.toDate());
  
                // if(occurance.length > 0) {
                //   if(alreadyfetchedEvent && alreadyfetchedEvent.length > 0){
                //     let flag = false;
                //     for(let t = 0; t < alreadyfetchedEvent.length; t++){
                      
                //       if(temp.selfID === alreadyfetchedEvent[t].selfID){
                //         flag = true;
                //       }
                //     }
                //     if(!flag){
                //       tempVitualRepeat.push(temp);
                //     }
                //   }
                //   else{
                //     tempVitualRepeat.push(temp);
                //   }
                  
                  
                // }
              }
  
            }
          }
          else if(temp.repeat && temp.repeat.length > 0 && temp.repeat[0] ==='self-paced'){
            //tempVirtual.push(temp);
            if(alreadyfetchedEvent && alreadyfetchedEvent.length > 0){
              let flag = false;
              for(let t = 0; t < alreadyfetchedEvent.length; t++){
                
                if(temp.selfID === alreadyfetchedEvent[t].selfID){
                  flag = true;
                }
              }
              if(!flag){
                tempVirtualSP.push(temp);
              }
            }
            else{
              tempVirtualSP.push(temp);
            }
          }
          
        }

        for(let w = 0; w < tempVirtualOnce.length; w ++){
          tempVirtual.push(tempVirtualOnce[w]);
        }
        for(let w = 0; w < tempVitualRepeat.length; w ++){
          tempVirtual.push(tempVitualRepeat[w]);
        }
        for(let w = 0; w < tempVirtualSP.length; w ++){
          tempVirtual.push(tempVirtualSP[w]);
        }

        sorted_virtual = _.cloneDeep(tempVirtual);

        // sorted_virtual = sorted_virtual.sort((t1, t2) => {
        //   if (moment(t1.startTime).isAfter(moment(t2.startTime))) { return 1; }
        //   if (moment(t1.startTime).isBefore(moment(t2.startTime))) { return -1; }
        //   return 0;
        // });
        
        for(let w = 0; w < sorted_private.length; w ++){
          sorted_recommend.push(sorted_private[w]);
        }
        for(let w = 0; w < sorted_selfpaced.length; w ++){
          sorted_recommend.push(sorted_selfpaced[w]);
        }
        for(let w = 0; w < sorted_virtual.length; w ++){
          sorted_recommend.push(sorted_virtual[w]);
        }
        for(let w = 0; w < sorted_repeat.length; w ++){
          sorted_recommend.push(sorted_repeat[w]);
        }
        for(let w = 0; w < sorted_once.length; w ++){
          sorted_recommend.push(sorted_once[w]);
        }
        
        
        

        self.sortedEventsOnallEventsForTemplateForRecommend = self.sortedEventsOnallEventsForTemplateForRecommend.concat(_.cloneDeep(sorted_recommend));
        self.cacheEventsForRecommend = self.cacheEventsForRecommend.concat(_.cloneDeep(sorted_recommend));
        if(self.cacheEventsForRecommend.length > 4){ 
          self.tempAllEventsArrayForRecommend = self.tempAllEventsArrayForRecommend.concat(_.cloneDeep(sorted_recommend));
          // return (sorted_upcoming);
          resolve(self.cacheEventsForRecommend);
        }
        else{
          if(moment(self.fetchDataForDateForRecommend).isSameOrBefore(moment(new Date()).add(6, 'M').toDate())){
        
            self.fetchDataForDateForRecommend = moment(self.fetchDataForDateForRecommend).add(15, 'days').endOf('date').toDate();

            self.tempAllEventsArrayForRecommend = self.tempAllEventsArrayForRecommend.concat(_.cloneDeep(sorted_recommend));
            
            // self.proceedWithGetCombineEvents(self.fetchDataForDateForRecommend,'Upcoming',self.tempAllEventsArray);
            let obj = {};
            obj['date'] = self.fetchDataForDateForRecommend;
            obj['type'] = 'Recommend';
            obj['tempEventArray'] = self.sortedEventsOnallEventsForTemplateForRecommend;
            reject(obj);
          }
          else{
            self.tempAllEventsArrayForRecommend = self.tempAllEventsArrayForRecommend.concat(_.cloneDeep(sorted_recommend));
            // self.sortedEventsOnallEventsForTemplateForRecommend  = _.cloneDeep(self.tempAllEventsArrayForRecommend);
            resolve((self.cacheEventsForRecommend));
          }
        }
        

    }
    else{
      if(moment(self.fetchDataForDateForRecommend).isSameOrBefore(moment(new Date()).add(6, 'M').toDate())){
        
        self.fetchDataForDateForRecommend = moment(self.fetchDataForDateForRecommend).add(15, 'days').endOf('date').toDate()
        
        //self.proceedWithGetCombineEvents(self.fetchDataForDateForRecommend,'Upcoming',self.allEventsArray.concat(_.cloneDeep(respArr)));
        let obj = {};
            obj['date'] = self.fetchDataForDateForRecommend;
            obj['type'] = 'Recommend';
            obj['tempEventArray'] = self.sortedEventsOnallEventsForTemplateForRecommend;
            reject(obj);
      }

      else{
        //self.tempAllEventsArrayForRecommend = self.tempAllEventsArrayForRecommend.concat(_.cloneDeep(sorted_upcoming));
        resolve((self.cacheEventsForRecommend));
      }

    }
    }
  });
  }

  getRecurringEventsForDate(date: moment.Moment): Event[] {
    const contextDate = date;
    const contextDay = contextDate.localeData().weekdays()[contextDate.day()];
    
    //Filtering for days here
    if (this.searchContext.days.length > 0 && !this.searchContext.days.includes(contextDay)) {
      return [];
    }

    const recurringEventsOfTheDate = this.recurringEvents.get(contextDay);
    //console.log('###### Recurring event for date: '+contextDay,recurringEventsOfTheDate)
    let returnVal: Event[] = [];
    if (recurringEventsOfTheDate) {
      returnVal = recurringEventsOfTheDate.filter(event => {
        if ((moment(event.startTime).isSameOrBefore(date, 'day') && moment(event.repeatEndDate).isSameOrAfter(date, 'day'))) {
          return event;
        }
      }).map(function (event: Event) {
        return event.getInstanceOfRecurringEvent(date);
      });
    }
    //console.log('###### Filterred recurring event for date: '+contextDay,returnVal)
    return returnVal ? returnVal : [];
  }

  addFeedback(uid: string, feedback : string): any {
    console.log('uid', uid);
    const self = this;
    return this.firestore.collection('backpack-feedback').add({uid:uid,feedback:feedback});
  }

  applyFilter(respArr ,campaignCode : any ,interests : any,distance : any, searchContext : SearchContext ) : Event[]{
    const finalReturnVal: Event[] = [];
    respArr.forEach(function (event: Event) {
      let eventCampaignCode = event.campaignCode;
      if (event.campaignCode === undefined || event.campaignCode === null) {
        eventCampaignCode = '';
      }
      
      if (campaignCode === eventCampaignCode) {
        //continue
      }
      else {
        return;
      }

      if (distance !== 0 && event.distance > distance) {
        return;
      }

      const contextDate = moment(event.startTime);
      const contextDay = contextDate.localeData().weekdays()[contextDate.day()];

      //Filtering for days here
      if (searchContext.days.length > 0 && !searchContext.days.includes(contextDay)) {
        return;
      }
    
      if (interests.length === 0 || interests.length === Utils.causeLabel.length) {
        finalReturnVal.push(event);
        return finalReturnVal;
      }
      
      for (const interest of event.causes) {
        if (interests.includes(interest)) {
          finalReturnVal.push(event);
          return finalReturnVal;
        }
      }      
    });
    if(finalReturnVal.length > 0){
    return finalReturnVal;
    }
    else{
      return respArr;
    }
  }

  applyFilterForRecommend(respArr ,interests : any, eventType : string ) : Event[]{
    const finalReturnVal: Event[] = [];
    respArr.forEach(function (event: Event) {
      if(eventType === 'Upcoming'){
        if (event.campaignCode && event.campaignCode != null && event.campaignCode != '') {
          return;
        }
        else{
          finalReturnVal.push(event);
        }
      }
      else if (eventType ==='Recommend'){
        if (event.campaignCode && event.campaignCode != null && event.campaignCode != '') {
          return;
        }
        else{
          finalReturnVal.push(event);
        } 
    }    
    });
    if(finalReturnVal.length > 0){
    return finalReturnVal;
    }
    else{
      return respArr;
    }
  }

  // ngo_partner opportunities
  read_partner_opportunity(id, callBackOnEventChange) {
    const self = this;

    // TODO: Need to plan for exceptions like No event on 4th of July

    const oneTimeEvents = this.firestore.collection(Constants.Collection_Scheduled_Event, ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      const email = this.auth.getCurrentUser();
      if (id) {
        query = query.where('ngo.id', '==', id).where('isDeleted', '==', false);
                  // .where('startTime', '>=', new Date())
                  // .orderBy('startTime');
      }
      return query;
    }).snapshotChanges();

    oneTimeEvents.subscribe(data1 => {
      // let data = _.cloneDeep(data1);
      // let finalData = [];
      // for(let i = 0; i < data.length; i++){
      //   let groupList =  [];
      //     if(self.auth.user &&  self.auth.user.eventGroupList){
      //       groupList = self.auth.user.eventGroupList;
      //     }
      //     let result = [];

      //     if(data[i].payload.doc.data()['eventGroupList'] && data[i].payload.doc.data()['eventGroupList'].length > 0){
      //       result = groupList.filter(function(item){ return data[i].payload.doc.data()['eventGroupList'].indexOf(item) > -1});   
      //       // return (result.length > 0); 
      //     }

      //     if(result && result.length > 0){
      //       data[i].payload.doc.data().isPrivate = true;
      //       finalData.push(data[i]);
      //     }
      //     else if(data[i] && (!data[i].payload.doc.data()['eventGroupList'] || data[i].payload.doc.data()['eventGroupList'].length == 0)){
      //       finalData.push(data[i]);
      //     }
      // }

      // let eventData = data1.filter(e =>{
      //   const da = e.payload.doc.data() as Event;
      //   let groupList =  [];
      //   if(self.auth.user &&  self.auth.user.eventGroupList){
      //     groupList = self.auth.user.eventGroupList;
      //   }
      //   let result = [];

      //   if(da['eventGroupList'] && da['eventGroupList'].length > 0){
      //     result = groupList.filter(function(item){ return da['eventGroupList'].indexOf(item) > -1});   
      //     // return (result.length > 0); 
      //   }

      //   if(result && result.length > 0){
      //     da.isPrivate = true;
      //     return da;
      //   }
      //   else if(da['eventGroupList'] && da['eventGroupList'].length == 0){
      //     return da;
      //   }
      //   else if(da['eventGroupList'] == undefined){
      //     return da;
      //   }
      // }).map(e => {
      //    const data = e.payload.doc.data();
      //   const lat = data['latlong'] ? data['latlong']._lat : 0;
      //   const lng = data['latlong'] ? data['latlong']._long : 0;
      //   console.log("Final " +"lat :" + lat + " "+"  lng : "+ lng);
      //   const tempEvent = new Event().deserialize(data);
      //   tempEvent.distance = tempEvent.lat !== 0 && tempEvent.lng !== 0 ?
      //   Utils.distance(self.currentLat, self.currentLng, tempEvent.lat, tempEvent.lng, 'M') : -1;
      //   tempEvent.pid = e.payload.doc.id;
      //   tempEvent.setEventId(e.payload.doc.id);
        
      //   return tempEvent;
        
      // });

      let eventData =[];
      for(let i = 0; i < data1.length; i++){
        const da = data1[i].payload.doc.data();
          const lat = da['latlong'] ? da['latlong']._lat : 0;
          const lng = da['latlong'] ? da['latlong']._long : 0;
          console.log("Final " +"lat :" + lat + " "+"  lng : "+ lng);
          const tempEvent = new Event().deserialize(da);
          tempEvent.distance = tempEvent.lat !== 0 && tempEvent.lng !== 0 ?
          Utils.distance(self.currentLat, self.currentLng, tempEvent.lat, tempEvent.lng, 'M') : -1;
          tempEvent.pid = data1[i].payload.doc.id;
          tempEvent.setEventId(data1[i].payload.doc.id);

          let groupList =  [];
          if(self.auth.user &&  self.auth.user.eventGroupList){
            groupList = self.auth.user.eventGroupList;
          }
          let result = [];

          if(tempEvent['eventGroupList'] && tempEvent['eventGroupList'].length > 0){
            result = groupList.filter(function(item){ return tempEvent['eventGroupList'].indexOf(item) > -1});   
            // return (result.length > 0); 
          }

          if(result && result.length > 0 && self.auth.user.sio && self.auth.user.sio['id'] === tempEvent.ngo.id){
            tempEvent.isPrivate = true;
            if(tempEvent.campaignCode == undefined || tempEvent.campaignCode == null || tempEvent.campaignCode == ''){
              eventData.push(tempEvent);
            }
          }
          else if(tempEvent && (!tempEvent['eventGroupList'] || tempEvent['eventGroupList'].length == 0)){
            if(tempEvent.campaignCode == undefined || tempEvent.campaignCode == null || tempEvent.campaignCode == ''){
              eventData.push(tempEvent);
            }
          }
          else if(tempEvent['eventGroupList'] == undefined){
            if(tempEvent.campaignCode == undefined || tempEvent.campaignCode == null || tempEvent.campaignCode == ''){
              eventData.push(tempEvent);
            }
          }
          
          //return eventData;
      }
      callBackOnEventChange(eventData);
    });
  }

  fetchEventBasedOnFeaturedEvent(id) : Promise<any>{
  return this.firestore.collection('scheduled-event').ref.doc(id).get();
  }

  
  

  firstFileToBase64(fileImage: File): Promise<{}> {
    return new Promise((resolve, reject) => {
      let fileReader: FileReader = new FileReader();
      if (fileReader && fileImage != null) {
        fileReader.readAsDataURL(fileImage);
        fileReader.onload = () => {
        
          resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
          reject(error);
        };
      } else {
        reject(new Error('No file found'));
      }
    });
  }

  filterEventDiscover(){
    const self = this;
    self.filterEventsOnallEventsForTemplate = [];
    let temp  = _.cloneDeep(self.allEventsArrayForFilterScreen);
    temp = self.applyFilterOnEventFromDiscover(temp,self.searchContext.campaignCode,self.searchContext.interests,self.searchContext.distance,self.searchContext)
    self.filterEventsOnallEventsForTemplate  = _.cloneDeep(temp);

  }

  applyFilterOnEventFromDiscover(respArr ,campaignCode : any ,interests : any,distance : any, searchContext : SearchContext ) : Event[]{
    const finalReturnVal: Event[] = [];
    const self = this;
    respArr.forEach(function (event: Event) {
      let eventCampaignCode = event.campaignCode.toLowerCase();
      if(event.name == "Summer solistice"){
        console.log("Ashish");
      }
      if (event.campaignCode === undefined || event.campaignCode === null || event.campaignCode === '') {
        eventCampaignCode = '';
      }
      
      if (campaignCode != undefined && campaignCode != '' && campaignCode === eventCampaignCode) {
            // finalReturnVal.push(event);
            // return finalReturnVal;
      }
      else if(campaignCode != undefined && campaignCode != '' && campaignCode != eventCampaignCode){
        return;
      }
      else if(campaignCode == '' && eventCampaignCode && eventCampaignCode != ''){
        return;
      }
      // else {
        
      // }

      if(distance && distance >0){
      event.distance = event.lat !== 0 && event.lng !== 0 ?
            Utils.distance(self.currentLat, self.currentLng, event.lat, event.lng, 'M') : -1;
      if (event.distance == -1 || (+distance !== 0 && event.distance > +distance)) {
        return;
      }
    }

      //Filtering for days here
      if(event.repeat && searchContext.days && searchContext.days.length > 0){
        if(event.repeat[0] =='Once'){
          const contextDate = moment(event.startTime);
          const contextDay = contextDate.localeData().weekdays()[contextDate.day()];

          
          if (searchContext.days.length > 0 && !searchContext.days.includes(contextDay)) {
            return;
          }
        }
        else if(event.repeat[0] =='self-paced'){

          let calNoDays = moment(event.repeatEndDate).diff(moment(event.startTime),'days');
          if(calNoDays >= 7){
            
          }
          else{
            let daysArray = [];
            let weekday = new Array(7);
            weekday[0] = "Sunday";
            weekday[1] = "Monday";
            weekday[2] = "Tuesday";
            weekday[3] = "Wednesday";
            weekday[4] = "Thursday";
            weekday[5] = "Friday";
            weekday[6] = "Saturday";
            for(let i = 0 ; i < 7; i++){
              let dayFetched = moment(event.startTime).add(i, 'days').endOf('date').toDate().getDay();
              daysArray.push(weekday[dayFetched]);
            }
            let enteredDays = searchContext.days;
            //let checker = (daysArray, enteredDays) => enteredDays.every(v => daysArray.includes(v));
            let checker = (arr, target) => target.every(v => arr.includes(v));
            if(checker(daysArray, enteredDays)){

            }
            else{
              return;
            }
          }
          
        }
        else if(event.repeat[0] !='Once' && event.repeat[0] != 'self-paced'){
          let enteredDays = searchContext.days;
          let repeatDays = event.repeat;
          let checker = (arr, target) => target.every(v => arr.includes(v));
            if(checker(repeatDays, enteredDays)){

            }
            else{
              return;
            }
        }
      }

      //Filtering Date
      if(event.repeat && searchContext.date && searchContext.date != ''){
        if(event.repeat[0] =='self-paced'){
          if((moment(event.startTime).startOf('day')).isSame(moment(searchContext.date).startOf('day'))
              || (moment(event.repeatEndDate).startOf('day')).isSame(moment(searchContext.date).startOf('day'))
              || (moment(event.startTime).startOf('day')).isBefore(moment(searchContext.date).startOf('day')) && 
              (moment(event.repeatEndDate).startOf('day')).isAfter (moment(searchContext.date).startOf('day'))){
          
           }
           else{
             return;
           }
        }
        else if(event.repeat[0] =='Once'){
          // event.startTime.setHours(0,0,0,0);
          // if(event.startTime.toISOString() === (searchContext.date)){
          //   finalReturnVal.push(event);
          //   return finalReturnVal;
          //  }
          if((moment(event.startTime).startOf('day')).isSame(moment(searchContext.date).startOf('day'))){

          }
          else{
            return;
          }
        }
        else if(event.repeat[0] !='Once' && event.repeat[0] != 'self-paced'){
          const rule = rrulestr(event['rrule']);
          let dat : Date = new Date(searchContext.date);
          dat.setHours(event.startTime.getHours(),event.startTime.getMinutes(),event.startTime.getSeconds(),event.startTime.getMilliseconds());
          // if(rule.all().includes(dat)){
          //   finalReturnVal.push(event);
          //   return finalReturnVal;
          // }
          let flag : boolean = false;
          for (const dd of rule.all()){
            if(dd.getTime() == dat.getTime()){
              // finalReturnVal.push(event);
              // return finalReturnVal;
              flag = true;
            }
          }
          if(!flag){
            return;
          }
        }
      }
    
      // if (interests.length === 0 || interests.length === Utils.causeLabel.length) {
      //   finalReturnVal.push(event);
      //   return finalReturnVal;
      // }
      
      if(interests && interests.length > 0){
        // for (const interest of event.causes) {
        //   if (interests.includes(interest)) {
        //     finalReturnVal.push(event);
        //     return finalReturnVal;
        //   }
        // }
        let evcause = event.causes;
          // let checker = (arr, target) => target.every(v => arr.includes(v));
          let checker = (arr, target) => target.some(v => arr.includes(v));
            if(checker(evcause, interests)){
              finalReturnVal.push(event);
              return finalReturnVal;
            }
            else{
              return;
            }
      }  
      else{
        finalReturnVal.push(event);
        return finalReturnVal;
      }    
    });
    // if(finalReturnVal.length > 0){
    // return finalReturnVal;
    // }
    // else{
    //   return respArr;
    // }
    return finalReturnVal;
  }


  updateCheckinStatus(userCheckInID): any {
    return this.firestore.collection('volunteer-user-event').doc(userCheckInID).update({
      isCheckedIn: true,
      isCheckedOut : false,
      checkInTime: new Date()
    });
  }

  updateCheckOutStatus(userCheckInID): any {
    return this.firestore.collection('volunteer-user-event').doc(userCheckInID).update({
      isCheckedIn: false,
      isCheckedOut: true,
      checkOutTime: new Date()
    });
  }

  updateCheckOutStatusForVirtual(userCheckInID): any {
    return this.firestore.collection('volunteer-user-event').doc(userCheckInID).update({
      isCheckedIn: false,
      isCheckedOut: true,
      checkInTime: new Date(),
      checkOutTime: new Date()
    });
  }

  read_scheduledEvent(eventId){
    return this.firestore.collection(Constants.Collection_Scheduled_Event).doc(eventId);
  }

  fetchscheduledEventBasedOnMappingID(eventId) : Promise<any>{
      
    return this.firestore.collection(Constants.Collection_Scheduled_Event).ref.where('isDeleted','==',false).where('eventMappingId','==',eventId).get();
  }

  fetchNgoId(email) : Promise<any>{
    const self = this;
      return new Promise<any>(function (resolve, reject) {
        self.firestore.collection(Constants.Collection_NGO_VOLUNTEER).ref
          .where('email', '==', (email))
          .get()
          .then(function (querySnapshot) {
            if (querySnapshot.size > 0) {
              
              //resolve("DATAPRESENT");
              let ev = '';
              querySnapshot.forEach(function (doc) {
                let obj = doc.data();
                ev = obj['ngoID'];
              });
              if(ev && ev != ''){
                resolve(ev);
              }
              else{
                resolve('');
              }
            }
            else {
              resolve('');
            }
          }, function (error) {
            reject(error);
          });
      });
  }

  updateLatLngFromDataService(){
    if(this.currentLat == 42.2808 && this.currentLng == -83.7430){
      if(this.dataService.currentLat != 42.2808 && this.dataService.currentLng != -83.7430){
        this.currentLat = this.dataService.currentLat;
        this.currentLng = this.dataService.currentLng;
      }
    }
  }

}
