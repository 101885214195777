import { OverlayEventDetail } from '@ionic/core';
import { Injectable } from '@angular/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { PromptModalPage } from 'src/app/prompt-modal/prompt-modal.page';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  isLoading: boolean;

  constructor(private alertCtrl: AlertController,
    private loadingController: LoadingController,
    private modalController: ModalController) { }

  async presentAlert(header: string, msg: string) {
    const alert = await this.alertCtrl.create({
      header: header,
      message: msg,
      buttons: ['OK']
    });
    await alert.present();
  }

  async presentAlertConfirm(msg: string) {
    const
      alert = await this.alertCtrl.create({
        header: 'Confirm!',
        message: msg,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              console.log('Confirm Cancel.');
            }
          }, {
            text: 'Okay',
            handler: () => {
              console.log('Confirm Okay');
            }
          }
        ]
      });

    await alert.present();
  }

  async present(msg?: string) {
    let message = msg;
    if (message == undefined || message.length == 0) {
      message = "Please wait"
    }
    this.isLoading = true;
    return await this.loadingController.create({
      message: message,
      duration: 5000,
    }).then(a => {
      a.present().then(() => {
        console.log('presented');
        if (!this.isLoading) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
  }

  async dismiss() {
    this.isLoading = false;
    return await this.loadingController.dismiss().then(() => console.log('dismissed'));
  }
  async showError(headerTitle: string = 'Oh No!',
  messageText: string = 'An error occurred, please try again.',
  buttonTitle: string = 'Ok', onDismiss?: (() => void)) {

  await this.showPrompt(messageText,headerTitle,AlertType.Error, [{
    title: buttonTitle,
    type: ButtonType.primary,
    onDismiss: onDismiss,
  }]);
}

async showInformation(headerTitle: string = 'Information', messageText: string,
  buttonTitle: string = 'Ok', onDismiss?: (()=>void)) {

  await this.showPrompt(messageText,headerTitle,AlertType.Information, [{
    title: buttonTitle,
    type: ButtonType.primary,
    onDismiss: onDismiss,
  }]);
}
async showInformationLogin(headerTitle: string = 'Information', messageText: string,
  buttonTitle: string = 'Ok', onDismiss?: (()=>void)) {

  await this.showPrompt(messageText,headerTitle,AlertType.NotHeaderTitle, [{
    title: buttonTitle,
    type: ButtonType.primary,
    onDismiss: onDismiss,
  }]);
}

async showWarning(headerTitle: string = 'Warning', messageText: string,
  buttonTitle: string = 'Ok', onDismiss?: (() => void)) {

  await this.showPrompt(messageText,headerTitle,AlertType.Warning, [{
    title: buttonTitle,
    type: ButtonType.primary,
    onDismiss: onDismiss,
  }]);
}

async showConfirm(headerTitle: string = 'Confirm',
  messageText: string ,
  button: IAlertButton[]) {
  await this.showPrompt(messageText,headerTitle,AlertType.Confirm, button);
}

async showPrompt(messageText: string,headerTitle: string,mode: AlertType, buttonList: IAlertButton[]) {
  const modal: HTMLIonModalElement =
    await this.modalController.create({
      component: PromptModalPage,
      componentProps: {
        messageText: messageText,
        headerTitle: headerTitle ? headerTitle : AlertType[mode],
        alertType: mode,
        buttonList: buttonList,
      }
    });


  modal.onDidDismiss().then((eventDetail: OverlayEventDetail) => {
    const btnObj = eventDetail.data as IAlertButton;
    if (btnObj != null && btnObj.onDismiss != null) {
      btnObj.onDismiss(btnObj.context);
    }
  });

  return await modal.present();
}
}

export enum AlertType {
Confirm = "Confirm",
Information = "Information",
Warning = "Warning",
Error = "Error",
NotHeaderTitle = ''
}
export interface IAlertButton {
title: string,
type: ButtonType,
onDismiss?: ((any) => void),
context?: any
}

export enum ButtonType {
primary,
secondary,
tertiary
}

