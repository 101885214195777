import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './common/auth.guard';

const routes: Routes = [
  { path: '', loadChildren: './tabs/tabs.module#TabsPageModule', canActivate: [AuthGuard] },
  { path: 'discover',loadChildren: './home/home.module#HomePageModule', canActivate: [AuthGuard] },
  { path: 'search',loadChildren: './search/search.module#SearchPageModule', canActivate: [AuthGuard] },
  { path: 'login', loadChildren: './login/login.module#LoginPageModule' },
  { path: 'signup', loadChildren: './signup/signup.module#SignupPageModule' },
  { path: 'Forgot/Resetyourpassword', loadChildren: './forgotpassword/forgotpassword.module#ForgotpasswordPageModule' },
  { path: 'register', loadChildren: './signup/register/register.module#RegisterPageModule' },
  { path: 'details', loadChildren: './home/details/details.module#DetailsPageModule', canActivate: [AuthGuard] },
  { path: 'home', loadChildren: './tabs/tabs.module#TabsPageModule', canActivate: [AuthGuard]},
  { path: 'wavierpage', loadChildren: './wavierpage/wavierpage.module#WavierpagePageModule' },
  { path: 'scheduleeventsdetails', loadChildren: './scheduleeventsdetails/scheduleeventsdetails.module#ScheduleEventsDetailsPageModule', canActivate: [AuthGuard] },
  { path: 'about', loadChildren: './about/about.module#AboutPageModule' },
  { path: 'availabilitycheck', loadChildren: './availabilitycheck/availabilitycheck.module#AvailabilitycheckPageModule' },
  { path: 'editprofile', loadChildren: './editprofile/editprofile.module#EditprofilePageModule' },
  { path: 'feedback',loadChildren: './feedback/feedback.module#FeedbackPageModule', canActivate: [AuthGuard] },
  { path: 'home1', loadChildren: './donate/donate.module#DonatePageModule' },
  { path: 'donate-category', loadChildren: './donate-category/donate-category.module#DonateCategoryPageModule' },
  { path: 'donate-category-items', loadChildren: './donate-category-items/donate-category-items.module#DonateCategoryItemsPageModule' },
  { path: 'donate-payment', loadChildren: './donate-payment/donate-payment.module#DonatePaymentPageModule' },
  { path: 'donate-thank', loadChildren: './donate-thank/donate-thank.module#DonateThankPageModule' },
  { path: 'recommend-partner', loadChildren: './recommend-partner/recommend-partner.module#RecommendPartnerPageModule' },
  { path: 'recommend-partner-thank', loadChildren: './recommend-partner-thank/recommend-partner-thank.module#RecommendPartnerThankPageModule' },
  { path: 'signup-initial', loadChildren: './signup/signup-initial/signup-initial.module#SignupInitialPageModule' },
  { path: 'donate-stripe-payment', loadChildren: './donate-stripe-payment/donate-stripe-payment.module#DonateStripePaymentPageModule' },
  { path: 'upload', loadChildren: './upload/upload.module#UploadPageModule' },
 { path: 'card-list', loadChildren: './card-list/card-list.module#CardListPageModule' },
  { path: 'privacy-policy', loadChildren: './privacy-policy/privacy-policy.module#PrivacyPolicyPageModule' },
  { path: 'terms-condition', loadChildren: './terms-condition/terms-condition.module#TermsConditionPageModule' },
  { path: 'partner-profile', loadChildren: './partner-profile/partner-profile.module#PartnerProfilePageModule' },
 {path:'partial',loadChildren:'./shared/components/partial/partial.module#PartialPageModule'},
  { path: 'donate-amount', loadChildren: './donate-amount/donate-amount.module#DonateAmountPageModule' },
  { path: 'campaign', loadChildren: './campaign/campaign.module#CampaignPageModule' },
  { path: 'campaign-educate', loadChildren: './campaign-educate/campaign-educate.module#CampaignEducatePageModule' },
  { path: 'sign-petition/:id', loadChildren: './sign-petition/sign-petition.module#SignPetitionPageModule' },
  { path: 'pledge/:id', loadChildren: './pledge/pledge.module#PledgePageModule' },
  { path: 'campaign-file', loadChildren: './campaign-file/campaign-file.module#CampaignFilePageModule' },
  { path: 'intro-page', loadChildren: './intro-page/intro-page.module#IntroPagePageModule' },
  { path: 'login-modal', loadChildren: './login-modal/login-modal.module#LoginModalPageModule' },
  { path: 'self-report', loadChildren: './self-report/self-report.module#SelfReportPageModule' },
{ path: 'skill-based', loadChildren: './skill-based/skill-based.module#SkillBasedPageModule' },
  { path: 'skill-application', loadChildren: './skill-application/skill-application.module#SkillApplicationPageModule' },
  { path: 'skill-approval', loadChildren: './skill-approval/skill-approval.module#SkillApprovalPageModule' },
  { path: 'prompt-modal', loadChildren: './prompt-modal/prompt-modal.module#PromptModalPageModule' },
   { path: 'friends', loadChildren: './chat/friends/friends.module#FriendsPageModule' },
  { path: 'group/:id', loadChildren: './chat/group/group.module#GroupPageModule' },
  { path: 'addmembers/:id', loadChildren: './chat/addmembers/addmembers.module#AddmembersPageModule' },
  { path: 'groupinfo/:id', loadChildren: './chat/groupinfo/groupinfo.module#GroupinfoPageModule' },
  { path: 'groups', loadChildren: './chat/groups/groups.module#GroupsPageModule' },
  { path: 'imagemodal', loadChildren: './chat/imagemodal/imagemodal.module#ImagemodalPageModule' },
  { path: 'message/:id', loadChildren: './chat/message/message.module#MessagePageModule' },
  { path: 'messages', loadChildren: './chat/messages/messages.module#MessagesPageModule' },
  { path: 'newgroup', loadChildren: './chat/newgroup/newgroup.module#NewgroupPageModule' },
  { path: 'blockedlist', loadChildren: './chat/blockedlist/blockedlist.module#BlockedlistPageModule' },
  { path: 'userinfo/:id', loadChildren: './chat/userinfo/userinfo.module#UserinfoPageModule' },
  { path: 'settings', loadChildren: './chat/settings/settings.module#SettingsPageModule' },

  { path: 'chat', loadChildren:'./chat/tabs/tabs.module#TabsPageModule' },
  { path: 'home-mockup', loadChildren: './home-mockup/home-mockup.module#HomeMockupPageModule' },
  { path: 'mockup-gallery', loadChildren: './mockup-gallery/mockup-gallery.module#MockupGalleryPageModule' },
  { path: 'mockup-educate', loadChildren: './mockup-educate/mockup-educate.module#MockupEducatePageModule' },
  { path: 'mockup-educate-details', loadChildren: './mockup-educate-details/mockup-educate-details.module#MockupEducateDetailsPageModule' },
  { path: 'mockup-stats', loadChildren: './mockup-stats/mockup-stats.module#MockupStatsPageModule' },
  { path: 'background', loadChildren: './background/background.module#BackgroundPageModule' },
  { path: 'background-step', loadChildren: './background-step/background-step.module#BackgroundStepPageModule' },
  { path: 'background-step2', loadChildren: './background-step2/background-step2.module#BackgroundStep2PageModule' },
  { path: 'background-step3', loadChildren: './background-step3/background-step3.module#BackgroundStep3PageModule' },
  { path: 'maps', loadChildren: './maps/maps.module#MapsPageModule' },
  { path: 'qrcode', loadChildren: './qrcode/qrcode.module#QrcodePageModule' },
  { path: 'barcode-scanner', loadChildren: './barcode-scanner/barcode-scanner.module#BarcodeScannerPageModule' }



]

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
