import { Deserializable } from './deserializable.model';
import * as moment from 'moment';
import * as firebase from 'firebase/app';
import { Constants } from '../utils/constants';
import * as _ from 'lodash';

export class Event implements Deserializable {

  pid: string;
  templateEventId: string;
  isApproved = false;
  isScheduled = false;
  description = '';
  name: string;
  physicalEffortNeeded = '';
  date: Date;
  endTime: Date;
  location: string = '';
  noOfSlots: number = 1;
  startTime: Date;
  isEdit = false;
  avgRating = 0.0;
  primaryImageURL = '';
  primaryVideoURL = '';
  imageMedia: string[] = [];
  videoMedia: string[] = [];
  taskIncluded = '';
  trainingRequired = false;
  volunteerBGCheckReqd = false;
  ngo: Partner;
  repeat: [string] = ['Once'];
  isRepeated = false;
  duration = 0;
  maxVolunteerPerSlot = 0;
  maxVolunteersForEvent = 0;
  minimumSlotDuration = 0;
  signupCount = 0;
  distance = -1;
  causes: number[] = [];
  lat = 0;
  lng = 0;
  parent: Object;
  latlong: firebase.firestore.GeoPoint;
  whyImportant = '';
  activityPlace = '';
  activityType = '';
  cancellationPolicy = '';
  checkInInstruction = '';
  dressCode = '';
  contactDetails  = '';
  isTraining = false;
  transportationRoute = '';
  repeatEndDate: Date;
  slotStartTime: Date;
  slotEndTime: Date;
  cancelledByUser = false;
  userCancellationTime: Date;
   id: string;
  isContactless = false;
  campaign  : any = {};
  isVirtual : boolean = false;
  instruction : string = '';
  link = [];
  slotArr =  [];
  startTimestamp: Date;
  isSkillsBased = false;
  links = '';
  eventType:string = '';
  eventMappingId : string = '';
  eventGroupList : any[] = [];
  isPrivate : boolean = false;
  campaignCode : string = '';
  dateSelected : Date;

  getEventId(): string {
    return this.id;
  }

  setEventId(id: string) {
    this.id = id;
  }

  deserialize(input: any): this {
    const tempThis = Object.assign(this, input);
    let lat = 0;
    let lng = 0;
    if(input['latlong'] && input['latlong'].l_ && input['latlong'].__){
      lat = input['latlong'] ? input['latlong'].l_ : 0;
      lng = input['latlong'] ? input['latlong'].__ : 0;
    }
    else if(input['latlong'] && input['latlong'].u_ && input['latlong'].h_){
      lat = input['latlong'] ? input['latlong'].u_ : 0;
      lng = input['latlong'] ? input['latlong'].h_ : 0;
    }
    tempThis.lat = lat;
    tempThis.lng = lng;
    if (tempThis.startTime && tempThis.endTime) {
      tempThis.date = tempThis.date.toDate();
      tempThis.startTime = tempThis.startTime.toDate();
      tempThis.endTime = tempThis.endTime.toDate();
      //tempThis.duration = (tempThis.endTime.getTime() - tempThis.startTime.getTime()) / (60 * 1000);
    }
    if (tempThis.repeatEndDate) {
      tempThis.repeatEndDate = tempThis.repeatEndDate.toDate();
    }
    if (tempThis.startTimestamp) {
      tempThis.startTimestamp = tempThis.startTimestamp.toDate();
    }
    if(tempThis.slotEndTime){
      tempThis.slotEndTime = new Date(tempThis.slotEndTime);
    }
    if(tempThis.slotStartTime){
      tempThis.slotStartTime = new Date(tempThis.slotStartTime);
    }
    // if(input.repeat && input.repeat.length > 0 && input.repeat[0]==='self-paced'){
    //   tempThis.duration = input.duration;
    // }
    tempThis.isEdit = false;
    return tempThis;
  }

  getInstanceOfRecurringEvent(date: moment.Moment): Event {
    if (this.isRepeated === false || date.isSame(this.date, 'day')) {
      return this;
    } else {
      const event = _.cloneDeep(this);
      event.parent = { id: event.pid, date: event.date, templateEventId: event.templateEventId, repeat: event.repeat};
      const contextDate = date.clone();

      event.date = contextDate.startOf('date').toDate();
      event.startTime.setFullYear(contextDate.year(), contextDate.month(), contextDate.date());
      event.endTime.setFullYear(contextDate.year(), contextDate.month(), contextDate.date());
      event.signupCount = 0;
      event.isRepeated = false;
      event.repeat = ['Once'];
      
      // This pid is removed because server function checks for pid for event which has real event
      // in schedule-event table
      delete event['pid'];

      return event;
    }
  }
}

export class SearchContext {
  public date: string = ''; // Today
  public distance = 0; // Anywhere
  public interests: number[] = []; // All Interests
  public partner = ''; // All Partners
  public campaignCode = '';
  public days: string[] = [];
  public isFilterEnabled = false;

  reset() {
    this.date = '';
    this.distance = 0;
    this.interests = [];
    this.campaignCode = '';
    this.days = [];
    this.partner = '';
    this.isFilterEnabled = false;
  }
}

export interface Partner {
  logo: string;
  name: string;
  mission: string;
  causes: string;
  id: string;
}
