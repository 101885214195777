import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from 'angularfire2/firestore';

@Injectable({
  providedIn: 'root'
})
export class NgoService {

  constructor(
    private db: AngularFirestore,
    private fireAuth: AngularFireAuth
  ) { }


  read_ngoDetail(docId) {
    return this.db.collection('ngo').doc(docId);
  }

  read_ngoDetailData(docId) {
     const self = this;
    let data = {};
    return new Promise<any>(function (resolve, reject) {
      self.db.collection('ngo').ref.where('id', '==', docId).get().then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          data['id'] = doc.id;
          data = doc.data();
        });
        resolve(data);
      }, function (error) {
        reject(error);
      });
    });
  }

  read_ngoDetailNew(docId) {
    const self = this;
    let data = {};
    return new Promise<any>(function (resolve, reject) {
      self.db.collection('ngo').ref.where('ngoMappingId', '==', docId).get().then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          data['id'] = doc.id;
          data = doc.data();
        });
        resolve(data);
      }, function (error) {
        reject(error);
      });
    });
  }

  getNgoUserDetail(email: string) {
    const self = this;
    let data = {};
    return new Promise<any>(function (resolve, reject) {
      self.db.collection('ngo-user-profile').ref.where('email', '==', email).get().then(function (querySnapshot) {
        if (!querySnapshot.empty) {
          data['id'] = querySnapshot.docs[0].id;
          data = querySnapshot.docs[0].data();
        }
        resolve(data);
      }, function (error) {
        reject(error);
      });
    });
  }


}
