import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { PledgeUser, PledgeUserMapping } from '../../models/pledge_user_mapping.model';
import { Pledge } from '../../models/pledge.model';
import { Auth } from 'src/app/services/auth/auth';

@Injectable({
  providedIn: 'root'
})
export class PledgeService {
  selectedPledge: Pledge;
  constructor(private db: AngularFirestore,
    private auth: Auth) { }

  addNewPledge(material): any{
    console.log('Pledge Data', material);
    const param = JSON.parse(JSON.stringify(material));
    const self = this;
    return new Promise<firebase.firestore.DocumentReference>(function (resolve, reject) {
      self.db.collection('pledge').add(param).then(doc => {
        resolve(doc);
      }, function (error) {
        reject(error);
      });
    });
  }

  fetchPledge(materialID) {
    let data = [];
    const self = this;
    return new Promise<any>(function (resolve, reject) {
      self.db.collection('pledge').doc(materialID).get().toPromise();
    });
  }


  fetchAllPledge() {
    return this.db.collection("pledge").get().toPromise();
  }

  fetchVolunteerCampaignPledge(pledgeID): Promise<any> {
    // let data  = [];
    // const self = this;
    // return new Promise<any>(function(resolve, reject) {
    //   self.db.collection('pledge').ref.where('campaignId', '==', campaignID).get().then(function (querySnapshot) {
    //     querySnapshot.forEach(function (doc) {
    //       let val = doc.data() as Pledge;
    //       val['id'] = doc.id;
    //       data.push(val);

    //       });
    //       if(data.length > 0){
    //       resolve(data);
    //       }
    //       else{
    //         reject("No Pledge for particular campaign : " + campaignID);
    //       }
    //   }, function(error) {
    //     reject(error);
    //   });
    // });
    return this.db.collection('pledge').doc(pledgeID).get().toPromise();
  }

  fetchPledgeDetails(campaignID, pledgeId) {
    let data = [];
    const self = this;
    return new Promise<any>(function (resolve, reject) {
      self.db.collection('pledge-user-mapping').ref.where('campaignId', '==', campaignID).where('pledgeId', '==', pledgeId).orderBy('updated_on', 'desc')
        .get().then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            let val = doc.data() as PledgeUserMapping;
            val['id'] = doc.id;
            data.push(val);

          });
          if (data.length > 0) {
            resolve(data);
          }
          else {
            reject("No Pledge for particular pledge : " + pledgeId);
          }
        }, function (error) {
          reject(error);
        });
    });
  }

  fetchUserPledgeDetails(campaignId, pledgeId, userId) {
    let data = [];
    const self = this;
    return new Promise<any>(function (resolve, reject) {
      self.db.collection('pledge-user-mapping').ref
        .where('user.id', '==', userId)
        .where('campaignId', '==', campaignId)
        .where('pledgeId', '==', pledgeId)
        .orderBy('updated_on', 'desc')
        .get().then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            let val = doc.data() as PledgeUserMapping;
            val['id'] = doc.id;
            data.push(val);

          });
          if (data.length > 0) {
            console.log(data);
            resolve(data[0]);
          }
          else {
            reject("No Pledge for particular pledge : " + pledgeId);
          }
        }, function (error) {
          reject(error);
        });
    });
  }

  addPledgeUserDetails(data: PledgeUserMapping): any {
    console.log('Pledge User Data', data);
    data.email = this.auth.user.email;
    const eventParam = Object.assign({},data);
    let obj = new PledgeUser();
    obj['id'] = this.auth.user.uid;
    obj['firstName'] = this.auth.user.firstName;
    obj['lastName'] = this.auth.user.lastName;
    eventParam['user'] = Object.assign({}, obj);
    eventParam['updated_on'] = new Date();
    const self = this;
    return new Promise<firebase.firestore.DocumentReference>(function (resolve, reject) {
      self.db.collection('pledge-user-mapping').add(eventParam).then(doc => {
        resolve(doc);
      }, function (error) {
        reject(error);
      });
    });
  }

  updatePledgeUserDetails(data): any {
    console.log('Pledge User Data', data);
    const eventParam = Object.assign({}, data);
    eventParam['updated_on'] = new Date();
    const self = this;
    return new Promise<void>(function (resolve, reject) {
      self.db.collection('pledge-user-mapping').doc(data.id).update(eventParam).then(doc => {
        resolve();
      }, function (error) {
        reject(error);
      });
    });
  }
}

export enum PledgeStatus {
  unpledged,
  pledged,
  completed
}
