import { Component, OnInit } from '@angular/core';
import {ModalController, NavParams, AlertController, NavController,Platform} from '@ionic/angular';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { Device } from '@ionic-native/device/ngx';
import * as compareVersions from 'compare-versions';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.page.html',
  styleUrls: ['./login-modal.page.scss'],
})
export class LoginModalPage implements OnInit {
  onClosedData : string = '';
  supportAppleSignIn = false;
  isWebPlatform = false;

  constructor(private modalController: ModalController,
    public alertController: AlertController,
    public navCtrl: NavController,
    public fireAuth: AngularFireAuth,
    private device: Device,
    private pltform: Platform,) { }

  ngOnInit() {
    if(this.pltform.is('mobileweb') || this.pltform.is('desktop')){
      this.isWebPlatform  = true;
    }
    else{
      this.isWebPlatform  = false;
    }
    
    
    if(this.pltform.is('ios') && this.pltform.is('cordova')) {
      if (compareVersions.compare(this.device.version, '13.0', '>=')) {
        this.supportAppleSignIn = true;
      }
    }
  }

  async closeModal(done: boolean) {
    if (done) {
      if(this.onClosedData){
      await this.modalController.dismiss(this.onClosedData);
      }
    } else {
      await this.modalController.dismiss();
    }
  }

  signup() {
    this.onClosedData = 'signup';
    this.closeModal(true);
  }

  login(){
    this.onClosedData = 'login';
    this.closeModal(true);
  }
}
